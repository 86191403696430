import React from "react";

import SaleCarousel from '../IndicatorCarousel/SaleCarousel';
// reactstrap components
import {  Container } from "reactstrap";

function HeaderSale() {
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <SaleCarousel />
          </div>
        </Container>
      </div>
    </>
  );
}

export default HeaderSale;
