import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';

import '../../assets/datatable/jquery.dataTables.min.css';
import api from '../../services/apiClient';
import { useModal } from '../../hooks/modal';

const $  = require( 'jquery' );
$.DataTable = require('datatables.net-bs4');

function DtInstaments() {
  const tableRef = useRef();
  const [datagrid , setDatagrid] = useState([]);

  const { toggleModal } = useModal();

  useEffect(() => {
    const getDatagrid = async () => {
      const response = await api.get(
        'instalment',
        {headers: {
            Authorization: `Bearer ${localStorage.getItem('@autocon:token')}`
        }}
      );
      setDatagrid(response.data);

      let $el = $(tableRef.current);
      $el.DataTable({
        destroy: true,
        "scrollX": true,
        "bProcessing": true,
        fixedHeader: true,
        language: {
          lengthMenu: "_MENU_",
          zeroRecords: "Sem registros cadastrados.",
          info: "Mostrar _PAGE_ de _PAGES_",
          infoEmpty: "Sem registros",
          infoFiltered: "(Filtrado por _MAX_ total de SENFs)",
          search: "Pesquisar",
          loadingRecords: "Carregando registros",
          processing: "Processando...",
          paginate: {
            first: ">",
            previous: "<",
            next: ">",
            last: "<"
          }
        },
      });
    }
    getDatagrid();
  },[]);

   return (
    <div>
      <table className="table table-striped table-bordered" width="100%" ref={tableRef} >
        <thead>
          <tr>
            <th>Ações</th>
            <th>Nº Contrato</th>
            <th>Nº Parcela</th>
            <th>Valor R$</th>
            <th>Dt. Vencimento</th>
            <th>Dt. Pagamento</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {datagrid.map((data) => (

              <tr key={data.cpf_cnpj}>
                <td>
                  <Button
                    type='button'
                    color="success"
                    size="sm"
                    onClick={() => toggleModal({type: 6, idata: data, size: 'xl'})}
                  >
                    <i className="ni ni-settings" />
                   </Button>
                </td>
                <td>{data.contract_id}</td>
                <td>Parcela {data.number}</td>
                <td>{data.value}</td>
                <td>{moment(data.due_date).format("DD/MM/YYYY")}</td>
                <td>{!!data.pay_date ? moment(data.pay_date).format("DD/MM/YYYY") : '--'}</td>
                <td><p className="text-info">Aberto</p></td>

              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
}

export default DtInstaments;
