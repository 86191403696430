import React, { useCallback, useRef, useState } from 'react';

import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from '../../../utils/getValidationErrors';
import classnames from 'classnames';
import moment from 'moment';

import {
  Row,
  Col,
  Container,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

import { useModal } from '../../../hooks/modal';
import { useToast } from '../../../hooks/toast';

import Input from '../../Input';
import InputMaskPhone from '../../InputMaskPhone';
import InputMaskCpfCnpj from '../../InputMaskCpfCnpj';
import InputMoney from '../../InputMoney';
import InputFile from '../../InputFile';
import ViewerFiles from '../../ViewerFiles';
import InputDate from '../../InputDate'
import Select from '../../Select';
import SelectSalesman from '../../SelectSalesman';
import SelectProduct from '../../SelectProduct';
import SelectPdv from '../../SelectPdv';
import api from '../../../services/apiClient';
import Payment from '../../Payment';
import TextArea from '../../TextArea';
import { unitys } from '../../../variables/unitys';


const ModalRegister = () => {

  const formRef = useRef(null);
  const { toggleModal } = useModal();
  const { addToast } = useToast();
  const [uuid] = useState(uuidv4());
  const [cpfData, setCpfData] = useState();
  const [activeTab, setActiveTab] = useState('1');
  const [startDate, setStartDate] = useState(new Date());

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const handleGetClient = useCallback(async (e) => {
    const response = await api.get(`client/${e.target.value}`);
    if (response.data.length > 0) {
      setCpfData({person: response.data[0]})
      formRef.current.setFieldValue('person.date_birth', moment(response.data[0].date_birth).format("DD/MM/YYYY"));
    };
  }, []);

  const handleUnityManager = useCallback(async(e) => {
    if (!e.target.value) {
      formRef.current.setFieldValue('unity', '');
      formRef.current.setFieldValue('manager', '');
      return;
    }
    const salesMan = await api.get(`salesmanager/${e.target.value}`);
    formRef.current.setFieldValue('unity', unitys[salesMan.data[0].unity_id]);
    const salesManager = await api.get(`salesmanager/${salesMan.data[0].parent_id}`);
    formRef.current.setFieldValue('manager', salesManager.data[0].name);

  },[]);

  const replaceMoney = useCallback((money) => {
    money = money.replace(/\./g,'')
    money = money.replace(',','.')
    return money
  },[]);

  const handleSubmit = useCallback(async (data, { reset }) => {
    try {
      formRef.current.setErrors({});

      const msg = 'Campo obrigatório';

      const schema = Yup.object().shape({
        person: Yup.object().shape({
          name: Yup.string().required(msg),
          cpf_cnpj: Yup.string().required(msg),
          genre: Yup.string().required(msg),
          date_birth: Yup.string().required(msg),
          email: Yup.string().email('Insira um email válido').required(msg).required(msg)
        }),
        contract: Yup.object().shape({
          parent_id: Yup.string().required(msg),
          pdv: Yup.string().required(msg),
          product_id: Yup.string().required(msg),
        }),
        file: Yup.string().required(msg)
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const user = JSON.parse(localStorage.getItem('@autocon:person'));
      data.contract.unity_id = user.unity_id;
      data.person.unity_id = user.unity_id;
      data.person.type_id = 2;

      data.contract.credit = replaceMoney(data.contract.credit);
      data.contract.value_instalment = replaceMoney(data.contract.value_instalment);
      data.payments.forEach((item, index) => {
        data.payments[index].value = replaceMoney(data.payments[index].value)
      });


      await api.post('contract', data);

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Venda cadastrada'
      });

      reset();
      setInterval(function () { document.location.reload(); }, 2000);


    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err)
        formRef.current.setErrors(errors);
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Problemas com servidor.'
      });
    }
  }, [addToast, replaceMoney]);

  return (
    <Container>
      <Form onSubmit={handleSubmit} initialData={cpfData} ref={formRef}>
        <Input type="hidden" name="contract.id" value={uuid} />
        <Input type="hidden" name="person.id" />

        <ModalHeader toggle={toggleModal} >Cadastro de Vendas</ModalHeader>
        <ModalBody>

          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { toggle('1'); }}
              >
                Cadastro
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => { toggle('2'); }}
              >
                Documentos
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <br />
              <Row>
                <Col><Input placeholder="Praça" type="text" name="unity" disabled /></Col>
                <Col><SelectSalesman name="contract.parent_id" onChange={handleUnityManager}/></Col>
                <Col><Input placeholder="Gerente" type="text" name="manager" disabled /></Col>
                <Col><Select type="select" name="contract.status_id"
                    options={[
                      { value: '', label: 'Status' },
                      { value: '1', label: 'Enviada' },
                      { value: '2', label: 'Em análise' },
                      { value: '3', label: 'Pendente documentação' },
                      { value: '4', label: 'Pendente doc. mas subiu p/ pagamento' },
                      { value: '6', label: 'Cancelada após input' },
                      { value: '7', label: 'Concluída' }
                    ]}
                  />
                </Col>
              </Row>
              <br />
              <Row xs="4">
                <Col xs="4">
                  <Input placeholder="Nome completo" type="text" name="person.name" />
                </Col>
                <Col>
                  <InputMaskCpfCnpj  placeholder="CPF/CNPJ" onBlur={handleGetClient} type="text" name="person.cpf_cnpj" />
                </Col>
                <Col>
                  <Select
                    name="person.genre"
                    value={cpfData && cpfData.person.genre}
                    options={[
                      { value: '', label: 'Gênero' },
                      { value: '1', label: 'Masculino' },
                      { value: '0', label: 'Feminino' }
                    ]}
                  />
                </Col>
                <Col><InputDate placeholder="Data de Nascimento" name="person.date_birth" /></Col>
              </Row>
              <br />
              <Row>
                <Col xs="4"><Input placeholder="Email" type="text" name="person.email" /></Col>
                <Col><InputMaskPhone placeholder="Celular 1" type="text" name="person.contacts[0].phone" /></Col>
                <Col><InputMaskPhone placeholder="Celular 2" type="text" name="person.contacts[1].phone" /></Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Input placeholder="Nº do Contrato" type="text" name="contract.number" />
                </Col>
                <Col>
                  <Select name="contract.group"
                    options={[
                      { value: '', label: 'Tipo do Grupo' },
                      { value: '1', label: 'Em Andamento' },
                      { value: '0', label: 'Em formação' },
                    ]}
                  />
                </Col>
                <Col>
                  <InputMoney placeholder="Valor da Crédito" type="text" name="contract.credit" />
                </Col>
                <Col>
                  <InputMoney placeholder="Valor da Parcela" type="text" name="contract.value_instalment" />
                </Col>
              </Row>
              <br />
              <Row>
              <Col>
                  <Input type="number" min="1" placeholder="Nº de Parcelas" name="contract.instalments" />
                </Col>
                <Col>
                  <Select type="select" name="contract.safe"
                    options={[
                      { value: '', label: 'Seguro' },
                      { value: '1', label: 'Sim' },
                      { value: '0', label: 'Não' },
                    ]}
                  />
                </Col>
                <Col><InputDate placeholder="Data do Input GM" dateFormat="dd/MM/yyyy" selected={startDate}
      onChange={date => setStartDate(date)} name="contract.input_date" /></Col>
                <Col>
                  <Select type="select" name="contract.vindi"
                      options={[
                        { value: '', label: 'Vindi' },
                        { value: '1', label: 'Sim' },
                        { value: '0', label: 'Não' },
                      ]}
                    />
                </Col>

              </Row>
              <br/>
              <Row>
                <Col>
                  <SelectProduct name="contract.product_id" />
                  </Col>
                <Col>
                  <SelectPdv name="contract.pdv" />
                  </Col>
                <Col style={{ textAlign: "right", paddingTop: 13 }}>Pagamento Adesão:</Col>
                <Col sm="5"><Payment data={[]}/></Col>
              </Row>
              <br/>
              <Row>
                <Col>
                  <label>Observações:</label>
                  <TextArea name="contract.obs" rows="3" />
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId="2">
              <br />
              <Row>
                <Col>
                  <ViewerFiles />
                </Col>
              </Row>
              <Row>
                <Col>
                  <br/>
                  <p className="text-center">Uploads Documentos</p>
                  <hr/>
                </Col>
              </Row>
              <Row>
                <Col><InputFile name="file" label="Contrato GM" doctype="17" uuid={uuid} /></Col>
                <Scope path="docfiles">
                  <Col><InputFile name="file[0]" label="Documento oficial com foto" doctype="18" uuid={uuid} /></Col>
                  <Col><InputFile name="file[1]" label="CPF" doctype="19" uuid={uuid} /></Col>
                  <Col><InputFile name="file[2]" label="Comprovante de residência" doctype="20" uuid={uuid} /></Col>
                  <Col><InputFile name="file[3]" label="Comprovante de pagamento" doctype="21" uuid={uuid} /></Col>
                  <Col><InputFile name="file[4]" label="Seguro" doctype="22" uuid={uuid} /></Col>
                </Scope>
              </Row>

            </TabPane>
          </TabContent>

        </ModalBody>
        <ModalFooter>
          <Button color="success" type="submit">Salvar</Button>{' '}
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Form>
    </Container>
  );
}

export default ModalRegister;
