import React, { useCallback, useRef } from 'react';

import { Form } from '@unform/web';
import * as Yup from 'yup';
import getValidationErrors from '../../../utils/getValidationErrors';

import {
  Row,
  Col,
  Container,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import { useModal } from '../../../hooks/modal';
import { useToast } from '../../../hooks/toast';

import Select from '../../../components/Select';
import api from '../../../services/apiClient';
import TextArea from '../../TextArea';

const ModalSale = ({ idata }) => {

  const formRef = useRef(null);
  const { toggleModal } = useModal();
  const { addToast } = useToast();

  const handleSubmit = useCallback(async (data, { reset }) => {
    try {
      formRef.current.setErrors({});

      // const msg = 'Campo obrigatório';
      // const phoneRegExp = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/;

      // const schema = Yup.object().shape({
      //   name: Yup.string().required(msg),
      //   email: Yup.string().email('Insira um email válido').required(msg).required(msg),
      //   cellphene: Yup.string().matches(phoneRegExp, 'Numero de telefone inválido'),
      // });

      // await schema.validate(data, {
      //   abortEarly: false,
      // });

      const user = JSON.parse(localStorage.getItem('@autocon:person'));
      data.parent_id = user.parent_id;
      data.unity_id = user.unity_id;
      data.type_id = 2;


      await api.post(
        'lead',
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@autocon:token')}`
          }
        }
      );

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Lead cadstrado'
      });

      reset();
      setInterval(function () { document.location.reload(true); }, 2500);

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err)
        formRef.current.setErrors(errors);
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Lead já foi cadastrado.'
      });
    }
  }, [addToast]);

  return (
    <Container>
      <Form onSubmit={handleSubmit} initialData={idata} ref={formRef}>
        <ModalHeader toggle={toggleModal} >BOAS VINDAS</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <br />
              <Row>
                <Col>
                  <Select type="select" name="status"
                    options={[
                      {value: '', label: 'Status'},
                      {value: '1', label: 'Docs e Pags pendentes'},
                      {value: '2', label: 'Docs pendentes'},
                      {value: '3', label: 'Pag. pendentes'},
                      {value: '4', label: 'Cancelado'},
                    ]}
                  />
                  <br />
                  <label for="obs">Observações</label>
                  <TextArea rows="5" name="obs" />
                </Col>
                <Col style={{height: 230, overflowY: 'scroll'}} className="border border-info rounded ">
                  <div style={{paddingTop: 15}}>
                    <strong>Pendente Documentos 01/01/2020</strong>
                    <p>Comprovante de endereço não está legivel</p>
                  </div>
                  <hr />
                  <div style={{paddingTop: 15}}>
                    <strong>Pendente Documentos 02/01/2020</strong>
                    <p>Comprovante de pagamento não está legivel</p>
                  </div>
                  <hr/>
                  <div style={{paddingTop: 15}}>
                    <strong>Pendente Documentos 02/01/2020</strong>
                    <p>Contrato ilegivel</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" type="submit">Salvar</Button>{' '}
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Form>
    </Container>
  );
}

export default ModalSale;
