import React, { useEffect } from 'react';

import { useToast } from '../../../hooks/toast';
import { Container } from './styles';


function Toast({ message, style }) {

  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };

  },[removeToast, message.id]);

  return (
   <Container
    type={message.type}
    style={style}
   >
    <i className="ni ni-notification-70" />
    <div>
      <strong>{message.title}</strong>
      {message.description && <p>{message.description}</p>}
    </div>
    <button onClick={() => removeToast(message.id)} type="button">
    <i className="ni ni-fat-remove" />
    </button>
   </Container>
  );
}

export default Toast;
