import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import moment from 'moment';

 function Input({ name,...props}){
   const inputRef = useRef(null);
   const { fieldName, defaultValue, error, clearError, registerField } = useField(name);
   const [borderInput, setBorderInput] = useState('form-control');
   const [currentDate, setCurrentDate] = useState(false);

   useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })

    const check = error ? 'form-control border border-danger' : 'form-control';
    setBorderInput(check);

   }, [fieldName, registerField, error])

   const handleDate = useCallback(() => {
    setCurrentDate(!currentDate);
    clearError();
   },[currentDate, clearError]);

   const handleClearDate = useCallback(() => {
    if (!inputRef.current.value) setCurrentDate(!currentDate);
   },[currentDate]);

   return (

    <>
     {
      !currentDate ?
      <input
        ref={inputRef}
        onFocus={() => handleDate()}
        className={borderInput}
        defaultValue={defaultValue ? moment(defaultValue).format("DD/MM/YYYY") : defaultValue}
        type='text'
        {...props}
        />
        :
        <input
        ref={inputRef}
        onFocus={clearError}
        onBlur={() => handleClearDate()}
        className={borderInput}
        defaultValue={defaultValue ? moment(defaultValue).format("DD/MM/YYYY") : defaultValue}
        type='date'
        {...props}
        />
    }
      {error && <small style={{display: 'block'}} className="text-danger">{error}</small>}
    </>
   );

 }

 export default Input;
