import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  Badge,
  Card,
  CardTitle,
  Button,
  Row,
  Col
} from 'reactstrap';

import api from '../../services/apiClient';
import InputFile from '../InputFile';
import { docStatusColor, docStatusTxt } from '../../variables/status';
import { useToast } from '../../hooks/toast';

const ViewFileSale = ({ dataFile, label, doctype, contract, documents }) => {

  const [docfile, setDocfile] = useState([]);
  const [disapprove, SetDisapprove] = useState(false);
  const [uploadView, SetUploadview] = useState(false);
  const [document, setDocument] = useState({});
  const [status, setStatus] = useState();
  const { addToast } = useToast();


  const txtRef = useRef(null);

  useEffect(() => {
    setDocfile(dataFile.filter(item => item.type_id === doctype));
    setDocument(documents.filter(item => item.type_id === doctype)[0]);
    setStatus(document.status)
  }, [dataFile, doctype, documents, document]);

  const handleApprove = useCallback(async () => {
    await api.put(`document/${document.id}`, { status: 2 })
    setStatus(2)
    addToast({
      type: 'success',
      title: 'Sucesso',
      description: 'Documento Aprovado'
    });
  }, [document, addToast]);

  const handleSendPendency = useCallback(async () => {
    const textMsg = txtRef.current.value;
    if(!txtRef.current.value){
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Preencha o campo observações.'
      });
      return;
    }

    await api.put(`document/${document.id}`, { status: 3, textMsg: textMsg })
    addToast({
      type: 'success',
      title: 'Sucesso',
      description: 'Documento Reprovado'
    });
    setStatus(3)
    SetDisapprove(false);
  }, [document, addToast]);

  const handleClearLife = useCallback(async (files) => {
    await files.forEach(element => {
      api.delete(`files/${element.id}`)
    });
    //SetUploadview(!uploadView)
    setDocfile([]);
  }, []);


  return (
    <>
      <Badge color={docStatusColor[status]}>{docStatusTxt[status]}</Badge>
      <Card body outline color="info">
        <CardTitle>{label}</CardTitle>
          {disapprove ?
            (
              <Row>
                <Col>
                  <textarea name="description" className="form-control" placeholder="Observações reprovação do documento." rows="5" ref={txtRef}></textarea>
                </Col>
              </Row>
            )
            :
            uploadView ?
              <InputFile name="file" label="Upload" />
              :
              <ul>
                {
                  docfile.length > 0 ?
                    docfile.map(item => (
                      <>
                        <li><a href={item.url} key={item.id} target="_Blank" rel="noopener noreferrer">{item.name}</a></li>
                      </>
                    ))
                    :
                    <p className="text-red">Documento Pendente</p>
                }
              </ul>

          }
        <hr />
        <Row>
          <Col>
            {!disapprove ?
              uploadView ?
                <Button title="Voltar" type="button" size="sm" onClick={() => SetUploadview(!uploadView)} color="info"><i className="ni ni-bold-left" /></Button>
                :
                docfile.length > 0 ?
                status !== '2' &&
                  <>
                    <Button title="Aprovar" type="button" onClick={() => handleApprove()} size="sm" color="success"><i className="ni ni-check-bold" /></Button>
                    <Button title="Reprovar" type="button" onClick={() => SetDisapprove(!disapprove)} size="sm" color="warning"><i className="ni ni-curved-next" /></Button>
                    <Button title="Apagar Arquivos" type="button" onClick={() => handleClearLife(docfile)} size="sm" color="danger"><i className="ni ni-fat-remove" /></Button>
                  </>
                  :
                  <Button title="Upload" type="button" onClick={() => SetUploadview(!uploadView)} size="sm" color="info"><i className="ni ni-cloud-upload-96" /></Button>
              :
              (
                <>
                  <Button title="Voltar" type="button" onClick={() => SetDisapprove(!disapprove)} size="sm" color="info"><i className="ni ni-bold-left" /></Button>
                  <Button title="Enviar Pendência" type="button" onClick={() => handleSendPendency()} size="sm" color="success"><i className="ni ni-send" /></Button>
                </>
              )
            }

          </Col>
        </Row>
      </Card>
    </>
  );
}

export default ViewFileSale;

