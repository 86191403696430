import React from 'react';

import {
  Card,
  Container,
  Row,
  Button,
  Col,
} from "reactstrap";

import Header from "../../components/Header";
import Sidebar from '../../components/Sidebar';
import NavbarDash from '../../components/NavbarDash';
import Footer from '../../components/Footer';
import DtClients from '../../components/DTables/DtClients';
import { useModal } from  '../../hooks/modal';

function Client() {
  const { toggleModal } = useModal();

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <NavbarDash />

        <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <div style={{padding:15}}>
              <div style={{paddingTop: 10, paddingBottom: 15}}>
                <Row>
                  <Col>
                    <Button color="primary" onClick={() => toggleModal({type: 2, idata: null, size: 'xl'})}>Novo Cliente</Button>
                  </Col>
                  <Col></Col>
                  <Col></Col>
                </Row>
              </div>
                <DtClients />
              </div>
            </Card>
          </div>
        </Row>
      </Container>

        <Container fluid>
          <Footer />
        </Container>
      </div>
    </>
  );
}

export default Client;
