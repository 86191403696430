import React, { useState, useCallback, useEffect } from 'react';

import InputFile from '../InputFile';

import {
  Badge,
  Card,
  CardTitle,
  Button,
  Row,
  Col
} from 'reactstrap';

import api from '../../services/apiClient';
import { docStatusColor, docStatusTxt } from '../../variables/status';

const ViewFileSale = ({ dataFile, label, doctype, uuid, documents  }) => {
  const [docfile, setDocfile] = useState([]);
  const [document, setDocument] = useState({});
  const [obs, setObs] = useState();
  const [viewObs, setViewObs] = useState(false);

  useEffect(() => {
    setDocfile(dataFile.filter(item => item.type_id === doctype));
    setDocument(documents.filter(item => item.type_id === doctype)[0]);
    setObs(document.obs);
  }, [dataFile, doctype, documents, document.obs]);


  const handleClearLife = useCallback(async (files) => {
    await files.forEach(element => {
      api.delete(`files/${element.id}`)
    });
    setDocfile([]);
  }, []);

  return (
    <>
    <Badge color={docStatusColor[document.status]}>{docStatusTxt[document.status]}</Badge>
    <Card body outline color="info">
      <CardTitle>
        {label}{' '}
      </CardTitle>

        {
          docfile.length > 0 ?
            <>
              {viewObs ?
                <p className="text-red">{obs}</p>
                :
                <ul>
                  {docfile.map((item, index) => (
                      <li key={index}><a href={item.url} key={item.id} target="_Blank" rel="noopener noreferrer">Arquivo {index + 1}</a></li>
                  ))}
                </ul>
              }
            </>
          :
          <InputFile name="file" label="Upload" uuid={uuid} doctype={doctype} />
        }

      {
        docfile.length > 0 &&
        <>
          <hr />
          <Row>
            <Col>
              <Button title="Apagar Arquivo" type="button" onClick={() => handleClearLife(docfile)} size="sm" color="danger"><i className="ni ni-fat-remove" /></Button>
              {obs && <Button title="Observações" type="button" onClick={() => setViewObs(!viewObs)} size="sm" color="info"><i className="ni ni-chat-round" /></Button>}
            </Col>
          </Row>
        </>
      }
    </Card>
    </>
  );
}

export default ViewFileSale;

