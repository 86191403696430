import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';


import '../../assets/datatable/jquery.dataTables.min.css';
import api from '../../services/apiClient';
import { useModal } from '../../hooks/modal';
import { contractStatus, contractColor } from '../../variables/status'
import { unitys } from '../../variables/unitys'

const $  = require( 'jquery' );
$.DataTable = require('datatables.net-bs4');

function DTables() {
  const tableRef = useRef();
  const [datagrid , setDatagrid] = useState([]);

  const { toggleModal } = useModal();

  useEffect(() => {
    const getDatagrid = async () => {
      const response = await api.get('contract');
      setDatagrid(response.data);

      let $el = $(tableRef.current);
      $el.DataTable({
        destroy: true,
        "scrollX": true,
        "bProcessing": true,
        fixedHeader: true,
        language: {
          lengthMenu: "_MENU_",
          zeroRecords: "Sem registros cadastrados.",
          info: "Mostrar _PAGE_ de _PAGES_",
          infoEmpty: "Sem registros",
          infoFiltered: "(Filtrado por _MAX_ total de SENFs)",
          search: "Pesquisar",
          loadingRecords: "Carregando registros",
          processing: "Processando...",
          paginate: {
            first: ">",
            previous: "<",
            next: ">",
            last: "<"
          }
        },
      });
    }
    getDatagrid();
  },[]);

   return (
    <div>
      <table className="table table-striped table-bordered" width="100%" ref={tableRef} >
        <thead>
          <tr>
            <th>Ações</th>
            <th>Praça</th>
            <th>Referência</th>
            <th>Vendedor</th>
            <th>Gerente</th>
            <th>Cliente</th>
            <th>CPF/CNPJ</th>
            <th>Celular</th>
            <th>Dt. Venda</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {datagrid.map((data, index) => (

              <tr key={index}>
                <td>
                  <Button
                    type='button'
                    color="success"
                    size="sm"
                    onClick={() => toggleModal({type: '4b', idata: data, size: 'xl'})}
                  >
                    <i className="ni ni-settings" />
                   </Button>
                </td>
                <td>{unitys[data.unity_id]}</td>
                <td>{data.input_date ?
                  moment(data.input_date).format("MM-YYYY")
                  :
                  moment(data.created_at).format("MM-YYYY")
                }</td>
                <td>{data.salesman}</td>
                <td>{data.salesmanager}</td>
                <td>{data.people.name}</td>
                <td>{data.people.cpf_cnpj}</td>
                <td>{data.people.contacts.length > 0 && data.people.contacts[0].phone}</td>
                <td>{moment(data.created_at).format("DD/MM/YYYY")}</td>
                <td>
                  <strong
                    className={contractColor[data.status_id]}>
                    {contractStatus[data.status_id]}
                  </strong>
                </td>

              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
}

export default DTables;
