import React from 'react';
import { Link } from "react-router-dom";

import {
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";

import logoImg from '../../assets/img/brand/logocrm.png';

function Sidebar() {
  return (
    <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"

          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          <NavbarBrand className="pt-0" to="/dashboard" tag={Link}>
              <img
                className="navbar-brand-img"
                src={logoImg}
                alt="logo Autocon CRM"
              />
          </NavbarBrand>


          {/* Collapse */}
          {/* <Collapse navbar > */}
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>

                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"

                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Form */}
            <Form className="mt-4 mb-3 d-md-none">
              <InputGroup className="input-group-rounded input-group-merge">
                <Input
                  aria-label="Pesquisar"
                  className="form-control-rounded form-control-prepended"
                  placeholder="Pesquisar"
                  type="Pesquisar"
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <span className="fa fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Form>
            {/* Navigation */}
            <Nav navbar>
              <NavItem>
                <NavLink to="/leads" tag={Link}>
                  <i className="ni ni-planet text-orange" />
                  Leads
                </NavLink>
              </NavItem>

              {/* <NavItem>
                <NavLink to="/nova-venda" tag={Link}>
                  <i className="ni ni-bag-17 text-blue" />
                  Vendas PDV
                </NavLink>
              </NavItem> */}

              <NavItem>
                <NavLink to="/vendas" tag={Link}>
                  <i className="ni ni-bag-17 text-blue" />
                  Vendas
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink to="/cadastros" tag={Link}>
                  <i className="ni ni-collection text-red" />
                  Cadastro
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink to="/parcelas" tag={Link}>
                  <i className="ni ni-single-copy-04 text-green" />
                  Parcelas
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink to="/boas-vindas" tag={Link}>
                  <i className="ni ni-satisfied text-info" />
                  Boas Vindas
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink>
                  <i className="ni ni-single-02 text-red" />
                  Usuários
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink href="#">
                  <i className="ni ni-spaceship text-yellow" />
                  Metas
                </NavLink>
              </NavItem>
            </Nav>
            {/* Divider */}
            <hr className="my-3" />
            {/* Heading */}
            <h6 className="navbar-heading text-muted"></h6>
            {/* Navigation */}
            <Nav className="mb-md-3" navbar>
              <NavItem>
                <NavLink href="#">
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#">
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#">
                </NavLink>
              </NavItem>
            </Nav>
          {/* </Collapse> */}
        </Container>
      </Navbar>
  );
}

export default Sidebar;
