import React, { useRef, useCallback } from 'react';

import { Row, Col, Button } from 'reactstrap'
import { Form } from '@unform/web';

import * as Yup from 'yup';
import getValidationErrors from '../../../../utils/getValidationErrors';

import Input from '../../../../components/Input';
import TextArea from '../../../TextArea';
import api from '../../../../services/apiClient';
import { useToast } from '../../../../hooks/toast';

const NewContact = ({ id }) => {
  const formRef = useRef(null);
  const { addToast } = useToast();

    const handleSubmit = useCallback(async (data, { reset }) => {
    try {

      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        contact_date: Yup.string().required('Data requerida')
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const user = JSON.parse(localStorage.getItem('@autocon:person'));
      data.parent_id = user.id;
      data.person_id = id;

      await api.post('schedule', data);

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Agendamento inserido'
      });

      // reset();
      setInterval(function () { document.location.reload(true); }, 2500);

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err)
        formRef.current.setErrors(errors);
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro Servidor',
        description: 'Tente novmente mais tarde'
      });
    }
  }, [addToast, id]);

  return (
    <>
      <Form onSubmit={handleSubmit} ref={formRef}>
      <Row>
        <Col>
          <label>Próximo Contato:</label>
          <Input type="date" name="contact_date" />
          <br />
          <TextArea name="obs" rows="3" placeholder="Observações para o agendamento" />
          <br/>
        </Col>
      </Row>
      <Row>
        <Col className="text-right">
         <Button color="success" type="submit">Salvar</Button>{' '}
         <br/>
        </Col>
      </Row>
      </Form>
    </>
  );
}

 export default NewContact;
