import React from 'react';
import { Container } from "reactstrap";



import NavbarDash from '../../components/NavbarDash';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import Home from '../../pages/Home';

function Dashboard () {


  return (
    <>
      <Sidebar />
      <div className="main-content">
        <NavbarDash title={'Home'}/>
        <Home />
        <Container fluid>
          <Footer />
        </Container>
      </div>
    </>
  );
}

export default Dashboard;
