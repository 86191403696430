import React, { useState } from 'react';

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';

import { useViewer } from '../../hooks/viewer';


const ViewerFiles = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const { previews } = useViewer();

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === previews.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? previews.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  return (
    <>
      <style>
        {
          `.custom-tag {
              max-width: 100%;
              height: 300px;
              background: black;
              padding-top: 30px;
            }`
        }
      </style>
      {

        previews.length !== 0 && (<Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
        >
          <CarouselIndicators items={previews} activeIndex={activeIndex} onClickHandler={goToIndex} />
          {
            previews.map((item) => (

                <CarouselItem
                  onExiting={() => setAnimating(true)}
                  onExited={() => setAnimating(false)}
                  className="custom-tag text-center"
                  tag="div"
                  key={item.id}
                >
                  <a target="_Blank" rel="noopener noreferrer" href={item.url} ><img src={item.url} alt={item.title} width="35%" height="85%"/></a>
                </CarouselItem>

            ))
          }
          <CarouselControl className="text-black" direction="prev" directionText="Previous" onClickHandler={previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
        )
      }
    </>
  );
}

export default ViewerFiles;
