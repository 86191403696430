import React from 'react';

import {
  Container,
  Row,
  Col,
  Input,
  CustomInput
} from 'reactstrap';

const FormUser = () => {
  return (
    <Container>
      <Row>
        <Col xs="5">
          <Input placeholder="Nome completo" type="text" name="name" />
        </Col>
        <Col>
          <Input placeholder="Usuário Ex: nome.sobrenome" type="text" name="username" />
        </Col>
        <Col>
          <Input placeholder="CPF" type="text" name="cpf_cnpj" />
        </Col>
      </Row>

      <br />

      <Row>
        <Col>
          <Input placeholder="E-mail" type="text" name="email" />
        </Col>
        <Col>
          <Input placeholder="Celular" type="text" name="cellphone" />
        </Col>
        <Col>
          <CustomInput type="select" name="type" >
            <option value="">Tipo de Usuário</option>
            <option value="1">Diretoria</option>
            <option value="2">Gerente</option>
            <option value="3">Administrativo</option>
            <option value="4">Financeiro</option>
            <option value="5">Vendendor</option>
          </CustomInput>
        </Col>

      </Row>
      <br />
      <Row>
        <Col>
          <CustomInput type="select" name="genre" >
            <option value="">Filial</option>
            <option value="1">Belém</option>
            <option value="0">Maraba</option>
            <option value="0">Paraubepas</option>
            <option value="0">Pernabuco</option>
          </CustomInput>
        </Col>

        <Col>
          <CustomInput type="select" name="genre" >
            <option value="">Superior imediato</option>
            <option value="1">Fulano</option>
            <option value="0">Ciclano</option>
            <option value="0">Beltrano</option>
            <option value="0">Hermano</option>
          </CustomInput>
        </Col>

        <Col>
          <Input type="select" name="status">
            <option>Status</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
          </Input>
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm="4"><Input placeholder="Senha" type="password" name="password" /></Col>
        <Col sm="4"><Input placeholder="Confirmação de Senha" type="password" name="confirmed_password" /></Col>
      </Row>
      <hr />
    </Container>
  );

}

export default FormUser;
