import React, { useState, useCallback, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { Scope } from '@unform/core';

import InputMoney from '../InputMoney';
import Select from '../Select';

const Payment = ({ data }) => {
  const [pays, setPays] = useState([]);
  const [firsRemove, setfirsRemove] = useState();

  useEffect(() => {
    if (data.length > 0){
      const paysValue = data.map((item, index) => {
        return {id: item.id}
      });
      setPays(paysValue);
      setfirsRemove(paysValue[0].id)
      return;
    }
    const id = uuidv4();
    setPays([{ id }]);
    setfirsRemove(id);

  }, [data, setPays, setfirsRemove]);

  const handleAddPay = useCallback(() => {
    const id = uuidv4();
    setPays([...pays, { id }]);
  }, [pays]);

  const handleRemovePay = useCallback((id) => {
    setPays(state => state.filter(pay => pay.id !== id));
  }, [setPays]);

  return (
    <>
      {
        pays.map((pay, index) => (

          <Row key={pay.id} style={{ marginBottom: 10 }} form>
            <Scope path={`payments[${index}]`}>
              <Col sm="5"><InputMoney name="value" placeholder="Valor R$" /></Col>
              <Col>
                <Select
                  style={{ display: 'inline' }}
                  name="type_id"
                  options={[
                    { value: '', label: 'Tipo pagmento' },
                    { value: '23', label: 'Cartão de Crédito' },
                    { value: '24', label: 'Cartão de Debito' },
                    { value: '25', label: 'Dinheiro' },
                    { value: '26', label: 'Transferência Cliente' },
                    { value: '27', label: 'Transferência Vendedor' },
                    { value: '28', label: 'Transferência Gerente' },
                    { value: '29', label: 'Deposito em conta' },
                  ]}
                />
              </Col>
              <Col sm={1}>
                {pay.id === firsRemove ?
                  (
                    <Button
                      type="button"
                      color="primary"
                      size="sm"
                      onClick={() => handleAddPay()}
                    >
                      <i className="ni ni-fat-add" />
                    </Button>
                  )
                  :
                  (
                    <Button
                      type="button"
                      color="danger"
                      size="sm"
                      onClick={() => handleRemovePay(pay.id)}
                    >
                      <i className="ni ni-fat-delete" />
                    </Button>
                  )
                }
              </Col>
            </Scope>
          </Row>
        ))
      }
    </>
  );

}

export default Payment;
