import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import { mask, unMask } from 'remask';

function InputMaskCpfCnpj({ name, ...props }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, error, clearError, registerField } = useField(name);
  const [borderInput, setBorderInput] = useState('form-control');

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })

    const check = error ? 'form-control border border-danger' : 'form-control';
    setBorderInput(check);

  }, [fieldName, registerField, error])

  const handleMask = useCallback(e => {
    const originalValue = unMask(e.target.value);
    const maskedValue = mask(originalValue, ['999.999.999-99', '999.999.999/9999-99']);
    inputRef.current.value = maskedValue;
  }, []);

  return (
    <>
      <input
        ref={inputRef}
        onFocus={clearError}
        className={borderInput}
        defaultValue={defaultValue}
        onChange={handleMask}
        {...props}
      />
      {error && <small style={{ display: 'block' }} className="text-danger">{error}</small>}
    </>
  );

}

export default InputMaskCpfCnpj;
