import React, { useState } from "react";
import Slider from "react-slick";

import SalesUnity from '../IndicatorCards/Register/SalesUnity';
import CancelSalesUnity from '../IndicatorCards/Register/CancelSalesUnity';


const SaleCarousel = () => {
  const [settings] = useState(
    {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4
    }
  )

  return (
    <>
      <Slider {...settings}>
        <div>
          <CancelSalesUnity unity={1} />
        </div>
        <div>
          <CancelSalesUnity unity={2} />
        </div>
        <div>
          <CancelSalesUnity unity={3} />
        </div>
        <div>
          <CancelSalesUnity unity={4} />
        </div>
        <div>
          <CancelSalesUnity unity={5} />
        </div>
        <div>
          <SalesUnity unity={1} />
        </div>
        <div>
          <SalesUnity unity={2} />
        </div>
        <div>
          <SalesUnity unity={3} />
        </div>
        <div>
          <SalesUnity unity={4} />
        </div>
        <div>
          <SalesUnity unity={5} />
        </div>
      </Slider>
    </>
  );
}

export default SaleCarousel;
