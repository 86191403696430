import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import { unMask } from 'remask';
import VMasker from 'vanilla-masker';

function InputMoney({ name, ...props }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error, clearError} = useField(name);
  const [borderInput, setBorderInput] = useState('form-control');

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });

    const check = error ? 'form-control border border-danger' : 'form-control';
    setBorderInput(check);

  }, [fieldName, registerField, error])

  const handleMask = useCallback(e => {
    const originalValue = unMask(e.target.value);
    const maskedValue = VMasker.toMoney(originalValue)
    inputRef.current.value = maskedValue;
  }, []);

  return (
    <>
      <input
        ref={inputRef}
        onFocus={clearError}
        className={borderInput}
        defaultValue={defaultValue ? VMasker.toMoney(defaultValue) : defaultValue}
        onChange={handleMask}
        {...props}
      />
      {error && <small style={{ display: 'block' }} className="text-danger">{error}</small>}
    </>
  );

}

export default InputMoney;
