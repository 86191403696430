import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';
import Lead from '../pages/Lead';
import Client from '../pages/Client';
import NewSale from '../pages/NewSale';
// import Sale from '../pages/Sale';
import Register from '../pages/Register';
import Instalment from '../pages/Instalment';
import Welcome from '../pages/Welcome';

function Routes (){
  return (
    <>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/dashboard" component={Dashboard} isPrivate />
        <Route path="/perfil-usuario" component={Profile} isPrivate />
        <Route path="/leads" component={Lead} isPrivate />
        <Route path="/clientes" component={Client} isPrivate />
        <Route path="/vendas" component={NewSale} isPrivate />
        <Route path="/cadastros" component={Register} isPrivate />
        <Route path="/parcelas" component={Instalment} isPrivate />
        <Route path="/boas-vindas" component={Welcome} isPrivate />
      </Switch>
    </>
  );
};

export default Routes;
