import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import { Progress } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

import api from '../../services/apiClient';
import { Container, Label, BtnCheck, BtnTrash } from './style'
import { useViewer } from '../../hooks/viewer';


function InputFile({ name, ...props }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, error, clearError, registerField } = useField(name);

  const [errorTypeFile, setErrorTypeFile] = useState();
  const [txtLabel, setTxtLabel] = useState(props.label);
  const [upPercentage, setUpPercentage] = useState();
  const [upDisabled, setUpDisabled] = useState(false);
  const [preview, setPreview] = useState(defaultValue);
  const [fileUpload, setFileUpload] = useState();
  const [showBtn, SetShowBtn] = useState(false);
  const [filesIds, setFilesIds] = useState()

  const { addView, removeView } = useViewer()

  const handlePreview = useCallback((e) => {
    const file = e.target.files;
    let ids = [];

    if (!file) {
      setPreview(null);
      setTxtLabel(props.label);
      return;
    }

    const checkTypeFile = Object.keys(file)
      .filter(key => file[key].type !== 'image/png'
        && file[key].type !== 'image/jpeg');

    if (checkTypeFile.length > 0) {
      setTxtLabel('Formato não permitido');
      setErrorTypeFile('form-control border border-danger text-danger');
      setTimeout(() => {
        setTxtLabel(props.label);
        setErrorTypeFile();
      }, 1500);
      return;
    }

    Object.keys(file).forEach((key) => {
      const previewURL = URL.createObjectURL(file[key]);
      const id = uuidv4();

      const viewer = {
        id: id,
        url: previewURL
      };
      addView(viewer);
      ids.push(id);

    });

    setFilesIds(ids);
    setPreview(URL.createObjectURL(file[0]));
    setTxtLabel();
    setFileUpload(file);
    SetShowBtn(true);
    clearError();
  }, [props.label, clearError, addView]);

  const handleCleanFile = useCallback(() => {
    setPreview(null);
    SetShowBtn(false);
    setTxtLabel(props.label);
    removeView(filesIds);
    inputRef.current.value = null;
  }, [props.label, removeView, filesIds])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref) {
        ref.value = '';
        setPreview(null);
      },
      setValue(_, value) {
        setPreview(value);
      }
    })
  }, [fieldName, registerField]);

  const handleUploadFile = useCallback(() => {

    Object.keys(fileUpload).forEach((key) => {
      const data = new FormData();
      data.append('file', fileUpload[key]);
      data.append('id', props.uuid);
      data.append('type_id', props.doctype);

      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);

          if (percent < 100) {
            setUpPercentage(percent)
          }
        }
      }

      api.post('files', data, options).then(res => {
        setUpPercentage(100)
        setTimeout(() => {
          setUpPercentage(0);
        }, 1000);
        setUpDisabled(true);
        SetShowBtn(false)
      });
    });

  }, [props.uuid, props.doctype, fileUpload]);

  return (
    <Container>
      <Label className={errorTypeFile}>
        {txtLabel}
        {preview && <><a target="_Blank" rel="noopener noreferrer" href={preview}><img src={preview} alt="Preview" width="100%" height="100%" /></a></>}
        <input
          ref={inputRef}
          onFocus={clearError}
          //className={borderInput}
          defaultValue={defaultValue}
          style={{ display: 'none' }}
          type="file"
          onChange={handlePreview}
          disabled={upDisabled}
          multiple
          {...props}
        />
      </Label>
      {error && <small style={{ display: 'block' }} className="text-danger">{error}</small>}
      {upPercentage > 0 && <Progress color="success" value={upPercentage} />}
      {showBtn &&
        <>
          <BtnCheck type="button" onClick={() => handleUploadFile()}><i className="ni ni-check-bold" /></BtnCheck>
          <BtnTrash type="button" onClick={() => handleCleanFile()}>X</BtnTrash>
        </>
      }
    </Container>
  );

}

export default InputFile;
