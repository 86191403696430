import React, { useEffect, useState } from 'react';

import { Row, Card, CardBody, CardTitle } from 'reactstrap'

import api from '../../../../services/apiClient';
import Loading from '../../../Loading';

const ScheduleDay = ({ attended, label }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getTotalSales = async () => {
      const response = await api.get(`scheduleday/${attended}`)
      setData(response.data)
      setLoading(false)
    }
    getTotalSales();

  }, [attended]);

  return (

    <Card style={{ marginLeft: 15,  height: 113}} className="card-stats mb-4 mb-xl-0">
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle
              tag="h5"
              className="text-uppercase text-muted mb-0 text-success"
            >
              {label}
            </CardTitle>
            <span className="h2 font-weight-bold mb-0 text-success">
            {
              loading ?
                <Loading />
                :
                data
            }
            </span>
            <small> /Dia</small>
          </div>
          {/* <Col className="col-auto">
            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
              <i className="fas fa-chart-bar" />
            </div>
          </Col> */}
        </Row>
        {/* <p className="mt-3 mb-0 text-muted text-sm">
          <span className="text-success mr-2">
            <i className="fa fa-arrow-up" />
            3.48%
          </span>{" "}
          <span className="text-nowrap">
            Comparado ao mês passado
          </span>
        </p> */}
      </CardBody>
    </Card>
  );
}

export default ScheduleDay
