import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';


function Select({ name, options,...props}){
  const inputRef = useRef(null);
  const { fieldName, error, clearError, defaultValue, registerField } = useField(name);
  const [borderInput, setBorderInput] = useState();

  useEffect(() => {
  registerField({
    name: fieldName,
    ref: inputRef.current,
    path: 'value'
  })

  const check = error ? 'form-control border border-danger' : 'form-control';
  setBorderInput(check);

  }, [fieldName, registerField, error])

  return (
  <>
    <select
      ref={inputRef}
      onFocus={clearError}
      className={borderInput}
      defaultValue={defaultValue}
      {...props}
    >
      {
        options.map((option) => (
          <option value={option.value} key={option.label}>{option.label}</option>
        ))
      }
    </select>
    {error && <small style={{display: 'block'}} className="text-danger">{error}</small>}
  </>
  );

}

 export default Select;
