import React, { useRef, useCallback } from 'react';

import { Row, Col, Button } from 'reactstrap';
import { Form } from '@unform/web';

import * as Yup from 'yup';
import getValidationErrors from '../../../../utils/getValidationErrors';

import Input from '../../../Input';
import InputMaskPhone from '../../../InputMaskPhone';
import api from '../../../../services/apiClient';
import { useToast } from '../../../../hooks/toast';


const EditLead = ({ idata }) => {
  const formRef = useRef(null);
  const { addToast } = useToast();

  const handleSubmit = useCallback(async (data, { reset }) => {
    try {

      formRef.current.setErrors({});
      const msg = 'Campo requerido';

      const schema = Yup.object().shape({
        name: Yup.string().required(msg),

      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api.put(`lead/${idata.id}`, data);

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Lead atualizado'
      });

      setInterval(function () { document.location.reload(true); }, 2200);

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err)
        formRef.current.setErrors(errors);
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro Servidor',
        description: 'Tente novmente mais tarde'
      });
    }
  }, [addToast, idata.id]);

  return (

    <Form onSubmit={handleSubmit} initialData={idata} ref={formRef}>
      <Row>
        <Col>
          <Input placeholder="Nome" type="text" name="name" />
          <br />
          <InputMaskPhone placeholder="Celular" type="text" name="contacts[0].phone" />
          <br />
          <Input placeholder="E-mail" type="text" name="email" />
          <br />
        </Col>
      </Row>
      <Row>
        <Col className="text-right">
          <Button color="success" type="submit">Salvar</Button>{' '}
        </Col>
      </Row>
    </Form>

  );
}

export default EditLead
