import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';

import {
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

// utilizar defaultValue

 function InputAuth({ name, ...props}){
   const inputRef = useRef(null);
   const { fieldName, error, registerField } = useField(name);
   const [borderInput, setBorderInput] = useState('input-group-alternative');

   useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })

    const check = error ? 'border border-danger' : 'input-group-alternative';
    setBorderInput(check);

   }, [fieldName, registerField, error])

   return (
    <>
      <InputGroup className={borderInput}>
        {
          props.icon &&
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className={props.icon} />
            </InputGroupText>
          </InputGroupAddon>
        }
        <input ref={inputRef}  className="form-control" {...props} />
      </InputGroup>
      {error && <small className="text-danger">{error}</small>}
    </>
   );

 }

 export default InputAuth;
