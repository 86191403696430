import React, { useCallback, useRef, useState } from 'react';
import classnames from 'classnames';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import getValidationErrors from '../../../utils/getValidationErrors';

import {
  Row,
  Col,
  Container,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

import { useModal } from '../../../hooks/modal';
import { useToast } from '../../../hooks/toast';

import Input from '../../../components/Input';
import InputMaskPhone from '../../../components/InputMaskPhone';
import api from '../../../services/apiClient';
import TextArea from '../../TextArea';
import Select from '../../Select';
import SelectProduct from '../../SelectProduct';
import SelectPdv from '../../SelectPdv';
import ViewFileSale from '../../ViewFileSale';
import ViewerFiles from '../../ViewerFiles';


const ModalNewSaleEdit = ({ idata }) => {


  const formRef = useRef(null);
  const { toggleModal } = useModal();
  const { addToast } = useToast();
  const [activeTab, setActiveTab] = useState('1');
  const [unity, setUnity] = useState();

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const handleSubmit = useCallback(async (data, { reset }) => {
    try {
      // formRef.current.setErrors({});
      // const msg = 'Campo obrigatório';

      // const schema = Yup.object().shape({
      //   cpf_cnpj: Yup.string().required(msg),
      //   pdv: Yup.string().required(msg),
      //   name: Yup.string().required(msg),
      //   email: Yup.string().email('Insira um email válido').required(msg).required(msg),
      //   product_id: Yup.string().required(msg),
      //   contacts: Yup.array().of(
      //     Yup.object().shape({
      //       phone: Yup.string().matches(phoneRegex, 'Numero de telefone inválido'),
      //     })
      //   ),
      //   file: Yup.string().required(msg)
      // });

      // await schema.validate(data, {
      //   abortEarly: false,
      // });

      await api.put(`sale/${data.id}`, data);

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Venda Editada'
      });

      setInterval(function () { document.location.reload(); }, 2000);

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err)
        formRef.current.setErrors(errors);
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Problemas na Edição'
      });
    }
  }, [addToast]);

  return (
    <Container>
      <Form onSubmit={handleSubmit} initialData={idata} ref={formRef}>
        <ModalHeader toggle={toggleModal} >CADASTRO VENDAS</ModalHeader>
        <ModalBody>

          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { toggle('1'); }}
              >
                Cadastro
          </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => { toggle('2'); }}
              >
                Documentos
          </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <br/>
              <Row>
                <Input type="hidden" name="id" />
                <Col>
                  <Input placeholder="CPF/CNPJ" type="text" name="people.cpf_cnpj" disabled />
                  <br />
                  <InputMaskPhone placeholder="Celular" type="text" name="people.contacts[0].phone" />
                  <br />
                  <Select type="select" name="unity_id" disabled
                    options={[
                      { value: '', label: 'Praça' },
                      { value: '1', label: 'Belém' },
                      { value: '2', label: 'Marabá' },
                      { value: '3', label: 'Parauapebas' },
                      { value: '4', label: 'São Luís' },
                      { value: '5', label: 'Aracaju' },
                    ]}
                  />
                  <br />
                  <SelectProduct name="product_id" value={idata.product_id} />
                </Col>
                <Col>
                  <Input placeholder="Nome do cliente" name="people.name" />
                  <br />
                  <Input placeholder="E-mail" type="text" name="people.email" />
                  <br />
                  <SelectPdv name="pdv" value={idata.pdv} unity={idata.unity_id} disabled/>
                </Col>
              </Row>
              <Row>
                <Col>
                  <br />
                  <TextArea name="obs" rows="3" disabled />
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId="2">
              <br/>
              <p className="text-center">Uploads Documentos</p>
              <hr/>
              <ViewerFiles />
              <br />
              <Row>
                <Col><ViewFileSale dataFile={idata.files} documents={idata.documents} label="Contrato GM" doctype={17} uuid={idata.id}
                /></Col>
                <Col><ViewFileSale dataFile={idata.files} documents={idata.documents} label="Documento oficial com foto" doctype={18} uuid={idata.id}
               /></Col>
                <Col><ViewFileSale dataFile={idata.files} documents={idata.documents} label="CPF"
                 doctype={19} uuid={idata.id}/></Col>
              </Row>
              <br/>
              <Row>
                <Col><ViewFileSale dataFile={idata.files} documents={idata.documents} label="Comprovante de residência"
                 doctype={20} uuid={idata.id}
                 /></Col>
                <Col><ViewFileSale dataFile={idata.files} documents={idata.documents} label="Comprovante de pagamento" doctype={21}
                uuid={idata.id} /></Col>
                <Col><ViewFileSale dataFile={idata.files} documents={idata.documents} label="Seguro" doctype={22}
                uuid={idata.id} /></Col>
              </Row>
            </TabPane>
          </TabContent>

        </ModalBody>
        <ModalFooter>
          <Button color="success" type="submit">Salvar</Button>{' '}
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Form>
    </Container>
  );
}

export default ModalNewSaleEdit;
