import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Form } from '@unform/web';
import * as Yup from 'yup';
import getValidationErrors from '../../../utils/getValidationErrors';
import classnames from 'classnames';
import moment from 'moment';

import {
  Row,
  Col,
  Container,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

import { useModal } from '../../../hooks/modal';
import { useToast } from '../../../hooks/toast';

import Input from '../../Input';
import InputDate from '../../InputDate';
import InputMaskPhone from '../../InputMaskPhone';
import InputMaskCpfCnpj from '../../InputMaskCpfCnpj';
import InputMoney from '../../InputMoney';
import Select from '../../Select';
import SelectSalesman from '../../SelectSalesman';
import SelectProduct from '../../SelectProduct';
import SelectPdv from '../../SelectPdv';
import api from '../../../services/apiClient';
import Payment from '../../Payment';
import FileValidation from '../../FileValidation';
import TextArea from '../../TextArea';
import ViewerFiles from '../../ViewerFiles';
import { unitys } from '../../../variables/unitys';

const ModalRegister = ({ idata }) => {
  const formRef = useRef(null);
  const { toggleModal } = useModal();
  const { addToast } = useToast();
  const [activeTab, setActiveTab] = useState('1');
  const [activeTab1, setActiveTab1] = useState('3');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const toggle1 = tab1 => {
    if (activeTab1 !== tab1) setActiveTab1(tab1);
  }

  useEffect(() => {
    formRef.current.setFieldValue('sale_date', moment(idata.sale_date).format("DD/MM/YYYY"));
    formRef.current.setFieldValue('unity', unitys[idata.unity_id]);
    formRef.current.setFieldValue('parent_id', idata.people.parent_id);
    const getSalesManager = async () => {
      const salesManager = await api.get(`salesmanager/${idata.parent_id}`);
      formRef.current.setFieldValue('sales_manager', salesManager.data[0].name);
    }
    getSalesManager();
  }, [idata.sale_date,
  idata.unity_id,
  idata.people.parent_id,
  idata.input_date,
  idata.parent_id,
  idata.id
  ]);

  const replaceMoney = useCallback((money) => {
    money = money.replace(/\./g, '')
    money = money.replace(',', '.')
    return money
  }, []);

  const handleSubmit = useCallback(async (data, { reset }) => {
    try {
      formRef.current.setErrors({});

      const msg = 'Campo obrigatório';

      const schema = Yup.object().shape({
        status_id: Yup.string().required(msg),
        parent_id: Yup.string().required(msg),
        // number: Yup.string().required(msg),
        // group:  Yup.string().required(msg),
        // credit
        // value_instalment
        // instalments
        // input_date
        // safe
        // vindi
        // product_id
        // people: Yup.object().shape({
        //     name: Yup.string().required(msg),
        //     genre: Yup.string().required(msg),
        //     date_birth: Yup.string().required(msg),
        //     email: Yup.string().email('Insira um email válido').required(msg).required(msg)
        //   })
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      data.credit = replaceMoney(data.credit);
      data.value_instalment = replaceMoney(data.value_instalment);
      data.payments.forEach((item, index) => {
        data.payments[index].value = replaceMoney(data.payments[index].value)
      });

      await api.put(`contract/${idata.id}`, data);

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Venda Editada'
      });

      setInterval(function () { document.location.reload(); }, 2500);

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err)
        formRef.current.setErrors(errors);
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Problemas com servidor.'
      });
    }
  }, [addToast, idata.id]);

  return (
    <Container>
      <Form onSubmit={handleSubmit} initialData={idata} ref={formRef}>
        <ModalHeader toggle={toggleModal} >Cadastro de Vendas</ModalHeader>
        <ModalBody>

          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { toggle('1'); }}
              >
                Cadastro
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => { toggle('2'); }}
              >
                Documentos
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <br />
              <Row>
                <Col></Col>
                <Col></Col>
                <Col></Col>
                <Col>
                  <small>Status</small>
                  <Select name="status_id"
                    options={[
                      { value: '', label: 'Status' },
                      { value: '1', label: 'Enviada' },
                      { value: '2', label: 'Em análise' },
                      { value: '3', label: 'Pendente documentação' },
                      { value: '4', label: 'Pendente doc. mas subiu p/ pagamento' },
                      { value: '6', label: 'Cancelada após input' },
                      { value: '7', label: 'Concluída' }
                    ]}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <small>Data da Venda</small>
                  <Input type="text" name="sale_date" disabled />
                </Col>
                <Col>
                  <small>Praça</small>
                  <Input type="text" name="unity" disabled />
                </Col>
                <Col>
                  <small>Vendedor(a)</small>
                  <SelectSalesman value={idata.parent_id} name="parent_id" />
                </Col>
                <Col>
                  <small>Gerente</small>
                  <Input type="text" name="sales_manager" disabled />
                </Col>
              </Row>
              <br />
              <Row xs="4">
                <Col xs="4">
                  <small>Nome completo</small>
                  <Input type="text" name="people.name" />
                </Col>
                <Col>
                  <small>CPF/CNPJ</small>
                  <InputMaskCpfCnpj type="text" name="people.cpf_cnpj" disabled />
                </Col>
                <Col>
                  <small>Gênero</small>
                  <Select
                    name="people.genre"
                    options={[
                      { value: '', label: 'Escolher' },
                      { value: '1', label: 'Masculino' },
                      { value: '0', label: 'Feminino' }
                    ]}
                  />
                </Col>
                <Col>
                  <small>Data Nascimento</small>
                  <InputDate name="people.date_birth" />
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs="4">
                  <small>E-mail</small>
                  <Input type="text" name="people.email" />
                </Col>
                <Col>
                  <small>Celular 1</small>
                  <InputMaskPhone type="text" name="people.contacts[0].phone" />
                </Col>
                <Col>
                  <small>Celular 2</small>
                  <InputMaskPhone type="text" name="people.contacts[1].phone" />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <small>Nº do Contrato</small>
                  <Input type="text" name="number" />
                </Col>
                <Col>
                  <small>Tipo do Grupo</small>
                  <Select name="group"
                    options={[
                      { value: '', label: 'Escolher' },
                      { value: '1', label: 'Em Andamento' },
                      { value: '0', label: 'Em formação' },
                    ]}
                  />
                </Col>
                <Col>
                  <small>Valor do Crédito</small>
                  <InputMoney type="text" name="credit" />
                </Col>
                <Col>
                  <small>Valor da Parcela</small>
                  <InputMoney type="text" name="value_instalment" />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <small>Nº de Parcelas</small>
                  <Input type="number" min="1" placeholder="Nº de Parcelas" name="instalments" />
                </Col>
                <Col>
                  <small>Seguro</small>
                  <Select type="select" name="safe"
                    options={[
                      { value: '', label: 'Escolher' },
                      { value: '1', label: 'Sim' },
                      { value: '0', label: 'Não' },
                    ]}
                  />
                </Col>
                <Col>
                  <small>Data do Input GM</small>
                  <InputDate name="input_date" />
                </Col>
                <Col>
                  <small>Vindi</small>
                  <Select type="select" name="vindi"
                    options={[
                      { value: '', label: 'Escolher' },
                      { value: '1', label: 'Sim' },
                      { value: '0', label: 'Não' },
                    ]}
                  />
                </Col>

              </Row>
              <br />
              <Row>
                <Col>
                  <small>Produto</small>
                  <SelectProduct name="product_id" value={idata.product_id} />
                </Col>
                <Col>
                  <small>PDV</small>
                  <SelectPdv name="pdv" value={idata.pdv} unity={idata.unity_id} disabled />
                </Col>
                <Col sm="2" className="text-right" style={{ paddingTop: 34 }}><small>Pagamento Adesão:</small></Col>
                <Col sm="5" style={{ paddingTop: 26 }}><Payment data={idata.payments} /></Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <small>Observações:</small>

                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab1 === '3' })}
                        onClick={() => { toggle1('3'); }}
                      >
                        Venda
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab1 === '4' })}
                        onClick={() => { toggle1('4'); }}
                      >
                        Cadastro
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab1}>
                    <TabPane tabId="3">
                      <br/>
                      <TextArea name="obs" rows="3" disabled />
                    </TabPane>
                    <TabPane tabId="4">
                      <br/>
                      <TextArea name="obs_register" rows="3"/>
                    </TabPane>
                  </TabContent>


                </Col>
              </Row>
            </TabPane>

            <TabPane tabId="2">
              <br />
              <ViewerFiles />
              <br />
              <Row>
                <Col><FileValidation dataFile={idata.files} documents={idata.documents} label="Contrato GM" doctype={17} contract={idata.id} /></Col>
                <Col><FileValidation dataFile={idata.files} documents={idata.documents} label="Documento oficial com foto" doctype={18} contract={idata.id} /></Col>
                <Col><FileValidation dataFile={idata.files} documents={idata.documents} label="CPF" doctype={19} contract={idata.id} /></Col>
              </Row>
              <br />
              <Row>
                <Col><FileValidation dataFile={idata.files} documents={idata.documents} label="Comprovante de residência" doctype={20} contract={idata.id} /></Col>
                <Col><FileValidation dataFile={idata.files} documents={idata.documents} label="Comprovante de pagamento" doctype={21} uuid={idata.id} contract={idata.id} /></Col>
                <Col><FileValidation dataFile={idata.files} documents={idata.documents} label="Seguro" doctype={22} contract={idata.id} /></Col>
              </Row>

            </TabPane>
          </TabContent>

        </ModalBody>
        <ModalFooter>
          <Button color="success" type="submit">Salvar</Button>{' '}
          <Button color="secondary" onClick={toggleModal}>Cancelar</Button>
        </ModalFooter>
      </Form>
    </Container>
  );
}

export default ModalRegister;
