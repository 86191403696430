import React from 'react'

import {
  Card,
  Container,
  Row,
} from "reactstrap";

import Header from "../../components/Header";
import Sidebar from '../../components/Sidebar';
import NavbarDash from '../../components/NavbarDash';
import Footer from '../../components/Footer';
import DtInstalments from '../../components/DTables/DtInstalments';

function Instalment() {

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <NavbarDash />

        <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <div style={{padding:15}}>
              <div style={{paddingTop: 10, paddingBottom: 15}}>

                <br />
                <DtInstalments />
              </div>
              </div>
            </Card>
          </div>
        </Row>
      </Container>

        <Container fluid>
          <Footer />
        </Container>
      </div>
    </>
  );
}

export default Instalment;
