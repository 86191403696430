import React from "react";

import RegisterCarousel from '../IndicatorCarousel/RegisterCarousel';
// reactstrap components
import {  Container } from "reactstrap";

function HeaderRegister() {
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <RegisterCarousel />
          </div>
        </Container>
      </div>
    </>
  );
}

export default HeaderRegister;
