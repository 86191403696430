import React, { useState, useEffect } from 'react';

import api from '../../services/apiClient';
import Select from '../Select';

const SelectPdv = (props) => {
  const [pdv, setPdv] = useState([]);

  useEffect(() => {
    //const user = JSON.parse(localStorage.getItem('@autocon:person'));
    const getPdv = async () => {
      if (props.unity) {
        const response = await api.get(`unity/${props.unity}`);
        let listPdv = [{value: '', label: 'PDV'}];
        response.data.forEach((item) => {
          listPdv.push({value: item.id, label: item.description })
        });
        setPdv(listPdv);}
    }
    getPdv();
  }, [setPdv, props.unity]);

  return (
    <Select options={pdv} {...props} />
  );
}

export default SelectPdv;
