import React, { useCallback, useRef } from 'react';

import { Scope } from '@unform/core';
import { Form } from '@unform/web';

import * as Yup from 'yup';
import getValidationErrors from '../../../utils/getValidationErrors';

import {
  Row,
  Col,
  Container,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import { useModal } from '../../../hooks/modal';
import { useToast } from '../../../hooks/toast';

import Input from '../../../components/Input';
import InputMaskPhone from '../../../components/InputMaskPhone';
import api from '../../../services/apiClient';
import TextArea from '../../TextArea';
import { phoneRegex } from '../../../variables/Regex';

const ModalLead = ({ idata }) => {

  const formRef = useRef(null);
  const { toggleModal } = useModal();
  const { addToast } = useToast();

  const handleSubmit = useCallback(async (data, { reset } ) => {
    try {
      formRef.current.setErrors({});
      const msg = 'Campo obrigatório';

      const schema = Yup.object().shape({
        name: Yup.string().required(msg),
        email: Yup.string().email('Insira um email válido').required(msg).required(msg),
        phone: Yup.string().matches(phoneRegex, 'Numero de telefone inválido'),
        schedule: Yup.object().shape({
          contact_date: Yup.string().required(msg)
        })
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const user = JSON.parse(localStorage.getItem('@autocon:person'));
      data.parent_id = user.id;
      data.unity_id = user.unity_id;
      data.type_id = 3;

      await api.post(
        'lead',
        data,
        {headers: {
            Authorization: `Bearer ${localStorage.getItem('@autocon:token')}`
        }}
      );

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Lead cadstrado'
      });

      reset();
      setInterval(function(){document.location.reload(); }, 2000);

    } catch (err) {
      if(err instanceof Yup.ValidationError){
        const errors = getValidationErrors(err)
        formRef.current.setErrors(errors);
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Lead já foi cadastrado.'
      });
    }
  },[addToast]);

  return (
    <Container>
      <Form onSubmit={handleSubmit} initialData={idata} ref={formRef}>
        <ModalHeader toggle={toggleModal} >Cadastro de Leads</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Input placeholder="Nome" type="text" name="name" />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <InputMaskPhone placeholder="Celular" type="text" name="phone" />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Input placeholder="E-mail" type="text" name="email" />
            </Col>
          </Row>
            <br />
            <Scope path="schedule">
              <Row>
                <Col>
                  <label>Próximo Contato:</label>
                  <Input type="date" name="contact_date" />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <TextArea  name="obs" rows="3" placeholder="Observações para o agendamento" />
                </Col>
              </Row>
            </Scope>
          </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">Salvar</Button>{' '}
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Form>
    </Container>
  );
}

export default ModalLead;
