import React, { useState, useEffect } from 'react';

import api from '../../services/apiClient';
import Select from '../Select';

const SelectProduct = (props) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      const response = await api.get('product');
      let listProducts = [{value: '', label: 'Produto'}];
      response.data.forEach((item) => {
        listProducts.push({value: item.id, label: `${item.id} - ${item.description}` })
      });
      setProducts(listProducts);
    }
    getProducts();
  }, [setProducts]);

  return (
    <Select options={products} {...props} />
  );
}

export default SelectProduct;
