import React, { useCallback, useRef } from 'react';

import { Form } from '@unform/web';
import * as Yup from 'yup';
import getValidationErrors from '../../../utils/getValidationErrors';

import {
  Row,
  Col,
  Container,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import { useModal } from '../../../hooks/modal';
import { useToast } from '../../../hooks/toast';

import Input from '../../../components/Input';
import Select from '../../../components/Select';
import api from '../../../services/apiClient';
import iconFile from '../../../assets/img/files.svg'

const ModalInstalment = ({ idata }) => {

  const formRef = useRef(null);
  const { toggleModal } = useModal();
  const { addToast } = useToast();

  const handleSubmit = useCallback(async (data, { reset }) => {
    try {
      formRef.current.setErrors({});

      //const msg = 'Campo obrigatório';
      // const phoneRegExp = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/;

      // const schema = Yup.object().shape({
      //   name: Yup.string().required(msg),
      //   email: Yup.string().email('Insira um email válido').required(msg).required(msg),
      //   cellphene: Yup.string().matches(phoneRegExp, 'Numero de telefone inválido'),
      // });

      // await schema.validate(data, {
      //   abortEarly: false,
      // });

      const user = JSON.parse(localStorage.getItem('@autocon:person'));
      data.parent_id = user.parent_id;
      data.unity_id = user.unity_id;
      data.type_id = 2;


      await api.post(
        'lead',
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@autocon:token')}`
          }
        }
      );

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Lead cadstrado'
      });

      reset();
      setInterval(function () { document.location.reload(true); }, 2500);

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err)
        formRef.current.setErrors(errors);
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Lead já foi cadastrado.'
      });
    }
  }, [addToast]);

  return (
    <Container>
      <Form onSubmit={handleSubmit} initialData={idata} ref={formRef}>
        <ModalHeader toggle={toggleModal} >Parcela de Contrato</ModalHeader>
        <ModalBody>


          <Row>
            <Col>
              <label>Nº do Contrato</label>
              <Input placeholder="Nº do Contrato" type="text" value="987654" name="contract" disabled/>
            </Col>
            <Col>
              <label>Nº da Parcela</label>
              <Input type="text" name="number" disabled/>
            </Col>
            <Col>
              <label>Valor da Parcela</label>
              <Input type="text" name="value" disabled/>
            </Col>
            <Col>
              <label>Data do Vencimento</label>
              <Input type="text" name="dt" value="28/08/2020" disabled/>
            </Col>

          </Row>
          <br />
          <Row>
            <Col>
              <Input type="text" placeholder="Correção da Parcela" name="current_credit" />
            </Col>
            <Col>
              <Input type="text" placeholder="Valor Pago" name="current_credit" />
            </Col>
            <Col>
              <Input type="text" placeholder="Data do Pagamento" name="sale_date" />
            </Col>

            <Col>

            <Select type="select" name="status"
                options={[
                  {value: '', label: 'Status'},
                  {value: '1', label: 'A Vencer'},
                  {value: '3', label: 'Vencido'},
                  {value: '2', label: 'Pago'},

                ]}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <p>Comprovantes:</p>
              <img width="65" height="65" alt="Doc Exemplo" src={iconFile} />
            </Col>
            <Col>
              <div className="border border-info rounded text-center">
                <br />
                <br />
                  Uploads de Compravantes
                <br />
                <br />
                <br />
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">Salvar</Button>{' '}
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Form>
    </Container>
  );
}

export default ModalInstalment;
