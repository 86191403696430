import React, { useCallback, useRef } from 'react';

import { Form } from '@unform/web';
import * as Yup from 'yup';
import getValidationErrors from '../../../utils/getValidationErrors';

import {
  Row,
  Col,
  Container,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import { useModal } from '../../../hooks/modal';
import { useToast } from '../../../hooks/toast';

import Input from '../../../components/Input';
import InputMaskCpfCnpj from '../../../components/InputMaskCpfCnpj';
import InputMaskPhone from '../../../components/InputMaskPhone';
import Select from '../../../components/Select';
import AddressContainer from '../../AddressContainer';

import api from '../../../services/apiClient';

const ModalClient = ({ idata }) => {

  const formRef = useRef(null);

  const { toggleModal } = useModal();
  const { addToast } = useToast();

  const handleSubmit = useCallback(async (data, { reset } ) => {
    try {
      formRef.current.setErrors({});

      const msg = 'Campo obrigatório';
      //const phoneRegExp = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/;

      const schema = Yup.object().shape({
        name: Yup.string().required(msg),
        cpf_cnpj: Yup.string().required(msg),
        genre: Yup.string().required(msg),
        date_birth: Yup.string().required('Insira data de nascimento'),
        email: Yup.string().email('Insira um email válido').required(msg),
        // phone: Yup.string().matches(phoneRegExp, 'Numero de telefone inválido'),
        // cellphone: Yup.string().matches(phoneRegExp, 'Numero de telefone inválido'),
        addresses:  Yup.array().of(
          Yup.object().shape({
            type_id: Yup.string().required(msg),
            street: Yup.string().required(msg),
            number: Yup.string().required(msg),
            cep: Yup.string().required(msg),
            district: Yup.string().required(msg),
            city: Yup.string().required(msg),
            state: Yup.string().required(msg),
          })
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const user = JSON.parse(localStorage.getItem('@autocon:person'));
      data.parent_id = user.parent_id;
      data.unity_id = user.unity_id;
      data.type_id = 1;


      await api.post(
        'client',
        data,
        {headers: {
            Authorization: `Bearer ${localStorage.getItem('@autocon:token')}`
        }}
      );

      //createDataTable('client');

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Cliente cadstrado'
      });

      reset();
      setInterval(function(){document.location.reload(true); }, 2500);

    } catch (err) {
      if(err instanceof Yup.ValidationError){
        const errors = getValidationErrors(err)
        formRef.current.setErrors(errors);
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'CPF ou CNPJ já foi cadastrado.'
      });
    }
  },[addToast]);

  return (
    <Container>
      <Form onSubmit={handleSubmit} initialData={idata} ref={formRef}>
        <ModalHeader toggle={toggleModal} >Cadastro de Clientes</ModalHeader>
        <ModalBody>

          <Row xs="4">
            <Col xs="4">
              <Input placeholder="Nome completo" type="text" name="name" />
            </Col>
          <Col>
            <InputMaskCpfCnpj placeholder="CPF/CNPJ" type="text" name="cpf_cnpj" />
          </Col>
          <Col>
            <Select
              name="genre"
              options={[
                {value: '', label: 'Gênero'},
                {value: '1', label: 'Masculino'},
                {value: '0', label: 'Feminino'}
              ]}
            />
          </Col>
          <Col><Input type="date" placeholder="Data de Nascimento" name="date_birth" /></Col>
        </Row>
        <br />
        <Row>
          <Col xs="4"><Input placeholder="Email" type="text" name="email" /></Col>
          <Col><InputMaskPhone placeholder="Telefone" type="text" name="phone" /></Col>
          <Col><InputMaskPhone placeholder="Celular" type="text" name="cellphone" /></Col>
        </Row>
        <hr />
        <AddressContainer />

        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">Salvar</Button>{' '}
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Form>
    </Container>
  );

}

export default ModalClient;
