import React, { useCallback, useState } from 'react';

import moment from 'moment';
import { Button } from 'reactstrap';

import api from '../../../../services/apiClient';

import { useToast } from '../../../../hooks/toast';

const ItemsSchedule = ({ schedules }) => {

  const [attended, setAttended] = useState(schedules.attended);
  const { addToast } = useToast();

  const handleAttended = useCallback(async (id) => {
    try {
      await api.put(`schedule/${id}`);
      addToast({
        type: 'success',
        title: 'Contato Antendido'
      });
      setAttended(true);

    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro no servidor',
        description: 'Tente novamente mais tarde.'
      });
    }
  },[addToast]);

  return (
    <div style={{ paddingTop: 20, paddingBottom: 10, borderBottom: '1px solid #dee2e6' }}>
      <strong>
        {moment(schedules.contact_date).format("DD/MM/YYYY")}
      </strong>
      <div style={{ display: 'inline', marginLeft: 10 }}>
        {!attended ?
          (<span className="badge badge-danger">Pendente</span>)
          :
          (<span className="badge badge-success">Realizado</span>)
        }
      </div>
      <p style={{ marginTop: 10 }}>{schedules.obs}</p>
      {!attended && <Button
        type='button'
        color="success"
        size="sm"
        onClick={() => handleAttended(schedules.id)}
        >
        {<i className="ni ni-check-bold" />}
      </Button>}
    </div>
  );

}

export default ItemsSchedule;
