import React, { useCallback } from 'react';

import {
  Row,
  Col,
  Container,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import { useModal } from '../../../hooks/modal';
import { useToast } from '../../../hooks/toast';

import api from '../../../services/apiClient';

const ModalSaleCancel = ({ idata }) => {

  const { toggleModal } = useModal();
  const { addToast } = useToast();

  const handleCancel = useCallback(async () => {
    try {

      await api.delete(`sale/${idata}`);

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Venda cancelada'
      });

      setInterval(function(){document.location.reload(); }, 2000);

    } catch (err) {

      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro no servidor'
      });
    }
  },[addToast, idata]);

  return (
    <Container>
        <ModalHeader toggle={toggleModal} >Cancelamento de Venda</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <p>Tem certeza que deseja cancelar essa venda?</p>
            </Col>
          </Row>
          </ModalBody>
        <ModalFooter>
          <Button color="danger" type="button" onClick={handleCancel}>Canelar</Button>{' '}
          <Button color="secondary" onClick={toggleModal}>Voltar</Button>
        </ModalFooter>
    </Container>
  );
}

export default ModalSaleCancel;
