import React from 'react';
import {
  Route as ReactDOMRoute,
  Redirect
 } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

function Route({ component: Component, ...props}) {
  const { user } = useAuth();

  return (
    <ReactDOMRoute
      {...props}
      render={({ location }) => {
        return !!props.isPrivate === !!user ? (
          <Component />
        ) : (
          <Redirect to={{
            pathname: props.isPrivate ? '/' : '/dashboard',
            state: { from: location }
            }}
          />
        )
      }}

    />
  );
}

export default Route;
