import React,
{
  createContext,
  useContext,
  useCallback,
  useState
}
from 'react';



const ViewerContext = createContext();

const ViewerProvider = ({ children }) => {
  const [previews, setPreviews] = useState([]);

  const addView = useCallback((viewer) => {
    setPreviews((state) => [...state, viewer]);
  },[]);

  const removeView = useCallback((ids) => {
    setPreviews(state => state.filter(views => !ids.includes(views.id)));
  },[]);
  const removeViewAll = useCallback((ids) => {
    setPreviews([]);
  },[]);

  return (
    <ViewerContext.Provider value={{ addView, removeView, removeViewAll,previews}}>
      {children}
    </ViewerContext.Provider>
  );
}

function useViewer() {
  const context = useContext(ViewerContext);

  if(!context) {
    throw new Error('useToast must be used within a ToastProvider.');
  }

  return context;
}

export { ViewerProvider, useViewer };
