import styled from 'styled-components';

export const Container = styled.div`
  float: left;

  & + div {
    margin-left: 20px;
  }
`;

export const Label = styled.label`
  display: block;
  padding: 8px;
  border: 1px solid #11cdef;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  text-align: center;
  font-size: 13px;
  cursor: pointer;

  &&:hover {
    background-color: #edf0ff;
    box-shadow: 1px 1px 10px #bbb;
  }

`;

export const BtnCheck = styled.button`
  flex: 1;
  flex-direction: row;
  width: 28px;
  height: 28px;
  border: 1px solid #2dce89;
  color: #2dce89;
  border-radius: 50%;
  padding: 2px;
  background-color:  #FFF;
  margin-left: 2%;

  &&:hover {
    background-color: #2dce89;

    i {
      color: #FFF;
    }
  }
`;

export const BtnTrash = styled.button`
  width: 28px;
  height: 28px;
  border: 1px solid #f5365c;
  color: #f5365c;
  border-radius: 50%;
  padding: 2px;
  background-color:  #FFF;
  margin-left: 2%;

  &&:hover {
    background-color: #f5365c;
    color: #FFF;
  }
`;
