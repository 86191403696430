import React, { useCallback, useRef } from 'react';

import { Form } from '@unform/web';
import * as Yup from 'yup';
import getValidationErrors from '../../../utils/getValidationErrors';

import {
  Row,
  Col,
  Container,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import { useModal } from '../../../hooks/modal';
import { useToast } from '../../../hooks/toast';

import Input from '../../../components/Input';
import InputMaskCpfCnpj from '../../../components/InputMaskCpfCnpj';
import InputMaskPhone from '../../../components/InputMaskPhone';
import api from '../../../services/apiClient';
import TextArea from '../../TextArea';
import SelectProduct from '../../SelectProduct';
import { paymentsTypes } from '../../../variables/types';
import FileValidation from '../../FileValidation';


const ModalSale = ({ idata }) => {
  const formRef = useRef(null);
  const { toggleModal } = useModal();
  const { addToast } = useToast();

  const handleSubmit = useCallback(async (data, { reset }) => {
    try {
      formRef.current.setErrors({});

      const msg = 'Campo obrigatório';

      const schema = Yup.object().shape({
        name: Yup.string().required(msg),
        email: Yup.string().email('Insira um email válido').required(msg).required(msg),
        // cellphene: Yup.string().matches(phoneRegExp, 'Numero de telefone inválido'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const user = JSON.parse(localStorage.getItem('@autocon:person'));
      data.parent_id = user.parent_id;
      data.unity_id = user.unity_id;
      data.type_id = 2;

      await api.post(
        'lead',
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@autocon:token')}`
          }
        }
      );

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Lead cadstrado'
      });

      reset();
      setInterval(function () { document.location.reload(); }, 2500);

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err)
        formRef.current.setErrors(errors);
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Lead já foi cadastrado.'
      });
    }
  }, [addToast]);

  return (
    <Container>
      <Form onSubmit={handleSubmit} initialData={idata} ref={formRef}>
        <ModalHeader toggle={toggleModal} >APROVAÇÃO DE VENDAS</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <InputMaskCpfCnpj placeholder="CPF/CNPJ" type="text" name="people.cpf_cnpj" disabled />

              <br />
              <InputMaskPhone placeholder="Celular" type="text" name="contacts[0].phone" />
              <br />
              <SelectProduct name="product_id" value={idata.product_id} />
            </Col>
            <Col>
              <Input placeholder="Nome do cliente" name="people.name" />
              <br />
              <Input placeholder="E-mail" type="text" name="people.email" />
              <br />
              <Row>
                <Col>
                  <label>Pagamentos:</label>
                  {idata.payments.map((item, index) => (
                    <p key={index}>
                      R$ {item.value} --- {paymentsTypes[item.type_id]} {' '}
                      <span className="badge badge-danger">Pendente</span> {' '}
                      <Button
                        type='button'
                        color="success"
                        size="sm"
                        >
                        <i className="ni ni-check-bold" />
                      </Button>
                    </p>
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <br />
              <TextArea name="obs" rows="3" placeholder="Observações sobre a venda." disabled />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <p className="text-center">Uploads Documentos</p>
              <FileValidation dataFile={idata.files[0]} label="Contrato GM" />
              <FileValidation dataFile={idata.files[1] && idata.files[1]} label="Documento oficial coom foto" />
              <FileValidation dataFile={idata.files[2] && idata.files[2]} label="CPF" />
              <FileValidation dataFile={idata.files[3] && idata.files[3]} label="Comprovante de Residencia" />
              <FileValidation dataFile={idata.files[4] && idata.files[4]} label="Comprovante de pagamento" />
              <FileValidation dataFile={idata.files[5] && idata.files[5]} label="Seguro" />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" type="submit">Salvar</Button>{' '}
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Form>
    </Container>
  );
}

export default ModalSale;
