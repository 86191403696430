import React, { useState } from "react";
import Slider from "react-slick";

import QtdSales from '../IndicatorCards/Sale/QtdSales';
import TotalSales from '../IndicatorCards/Sale/TotalSales';
import TotalSalesDay from '../IndicatorCards/Sale/TotalSalesDay';
import TotalPendecyMonth from '../IndicatorCards/Sale/TotalPendecyMonth';
import TotalPendecyDay from '../IndicatorCards/Sale/TotalPendecyDay';
import CancelMonth from '../IndicatorCards/Sale/CancelMonth';
import SafeMonth from '../IndicatorCards/Sale/SafeMonth';
import VindiMonth from '../IndicatorCards/Sale/VindiMonth';
import MiddleTickt from '../IndicatorCards/Sale/MiddleTickt';

const SaleCarousel = () => {
  const [settings] = useState(
    {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4
    }
  )

  return (
    <>
      <Slider {...settings}>
        <div>
          <TotalPendecyMonth />
        </div>
        <div>
          <TotalPendecyDay />
        </div>
        <div>
          <CancelMonth />
        </div>

        <div>
          <QtdSales />
        </div>
        <div>
          <TotalSales />
        </div>
        <div>
          <TotalSalesDay />
        </div>

        <div>
          <SafeMonth />
        </div>
        <div>
          <VindiMonth />
        </div>
        <div>
          <MiddleTickt />
        </div>
      </Slider>
    </>
  );
}

export default SaleCarousel;
