
import React, { useCallback, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Row,
  Col,
  NavbarBrand,
} from "reactstrap";

import { Form } from '@unform/web';
import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors'

import InputAuth from '../../components/InputAuth';
import logo from '../../assets/img/brand/logocrm.png'
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

function FormLogin () {
  const formRef = useRef(null);

  useEffect(() => {
    document.body.classList.add("bg-default");
  });

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(async (data) => {

    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        username: Yup.string().required('Nome de usuário obrigatório.'),
        password: Yup.string().required('Senha obrigatória.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await signIn(data);
      document.body.classList.remove("bg-default");

    } catch (err) {
      if(err instanceof Yup.ValidationError){
        const errors = getValidationErrors(err)
        formRef.current.setErrors(errors);
        return;
      }
      // Disparar Toast
      addToast({
        type: 'error',
        title: 'Acesso negado',
        description: 'Usuário ou senha inválidos'
      });
    }
  },[signIn, addToast])

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
            </div>
            <div className="btn-wrapper text-center">
              <NavbarBrand to="/" tag={Link}>
                <img alt="..." src={logo} />
              </NavbarBrand>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">

            <Form onSubmit={handleSubmit} ref={formRef} >
              <FormGroup className="mb-3">
                <InputAuth placeholder="Usuário" type="text" icon="ni ni-single-02" name="username" />
              </FormGroup>
              <FormGroup>
                <InputAuth placeholder="Senha" type="password" icon="ni ni-lock-circle-open" name="password"/>
              </FormGroup>
              {/* <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Lembre-me</span>
                </label>
              </div> */}
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Entrar
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={e => e.preventDefault()}
            >
              <small>Esqueceu sua senha?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={e => e.preventDefault()}
            >
              <small>Criar conta nova?</small>
            </a>
          </Col>
        </Row>
      </Col>
    </>
  );
}

export default FormLogin
