import React from 'react';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { ModalProvider } from './modal';
import { DataTableProvider } from './datatable';
import { ViewerProvider } from './viewer';

const AppProvider = ({ children }) => {
  return (
    <AuthProvider>
      <ToastProvider>
        <ViewerProvider>
          <DataTableProvider>
            <ModalProvider>
              {children}
            </ModalProvider>
          </DataTableProvider>
        </ViewerProvider>
      </ToastProvider>
    </AuthProvider>
  );
}

export default AppProvider;

