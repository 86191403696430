import React, { useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Scope } from '@unform/core';

import { Row, Col, Container,Button } from 'reactstrap';
import FieldsAddress from './FieldsAddress';

const AddressContainer = () => {

  const [addresses, setAddresses] = useState([]);
  const [firsRemove, setfirsRemove] = useState();

  useEffect(() => {
    const id = uuidv4();
    setAddresses([{id}]);
    setfirsRemove(id);

  },[setAddresses]);

  const handleAddAdress = useCallback(() => {
    const id = uuidv4();
    setAddresses([...addresses, {id}]);
  },[addresses]);

  const handleRemoveAdress = useCallback((id) => {
    setAddresses(state => state.filter(address => address.id !== id));
  },[setAddresses]);


  return (
    <Container>
      <Row>
        <Col>
          <p>Endereço:</p>
        </Col>
        <Col
          sm="1"
          md={{ size: 1, offset: 8 }}
          className="text-right"
          >
          <Button
            type="button"
            color="primary"
            size="sm"
            onClick={() => handleAddAdress()}
          >
            <i className="ni ni-fat-add" />
          </Button>
        </Col>
      </Row>

      {
        addresses.map((address, index) => (
          <div key={address.id}>
            {address.id !== firsRemove && (<hr/>)}
            <Scope path={`addresses[${index}]`}>
              <FieldsAddress />
            </Scope>
            <br/>
            {address.id !== firsRemove && (
              <Row>
                <Col
                sm="1"
                md={{ size: 1, offset: 11 }}
                className="text-right"
                >
                  <Button
                  type="button"
                  color="danger"
                  size="sm"
                  onClick={() => handleRemoveAdress(address.id)}
                  >
                    <i className="ni ni-fat-delete" />
                  </Button>
                </Col>
              </Row>
              )
            }
          </div>
        ))
      }
    </Container>
  );

}

export default AddressContainer;
