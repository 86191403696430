import React,
{
  createContext,
  useContext,
  useCallback,
  useState
}
from 'react';

import api from '../services/apiClient';

const DtableContext = createContext();

const DataTableProvider = ({ children }) => {

  const [data, setData] =  useState([]);

  const createDataTable = useCallback(async (dataRoute) => {
    const response = await api.get(
      dataRoute,
      {headers: {
          Authorization: `Bearer ${localStorage.getItem('@autocon:token')}`
      }}
    );
    setData(response.data);
  },[setData]);



  return (
    <DtableContext.Provider value={{ createDataTable, data }}>
      {children}
    </DtableContext.Provider>
  );
}

function useDataTable() {
  const context = useContext(DtableContext);

  if(!context) {
    throw new Error('useToast must be used within a ToastProvider.');
  }

  return context;
}

export { DataTableProvider, useDataTable };
