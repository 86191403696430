import React, { useState } from "react";
import Slider from "react-slick";

import TotalLeads from '../IndicatorCards/Lead/TotalLeads'
import LeadsMonth from '../IndicatorCards/Lead/LeadsMonth'
import LeadsDay from '../IndicatorCards/Lead/LeadsDay'
import ScheduleMonth from '../IndicatorCards/Lead/ScheduleMonth'
import ScheduleDay from '../IndicatorCards/Lead/ScheduleDay'
import ScheduleLate from '../IndicatorCards/Lead/ScheduleLate'


const LeadCarousel = () => {
  const [settings] = useState(
    {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4
    }
  )

  return (
    <>
      <Slider {...settings}>
        <div>
          <TotalLeads />
        </div>
        <div>
          <LeadsMonth />
        </div>
        <div>
          <LeadsDay />
        </div>
        <div>
          <ScheduleMonth attended={0} label={'Contatos Futuros'}/>
        </div>
        <div>
          <ScheduleDay attended={0} label={'Contatos'}/>
        </div>
        <div>
          <ScheduleLate />
        </div>
        <div>
          <ScheduleMonth attended={1} label={'Contatos Realizados'}/>
        </div>
        <div>
        <ScheduleDay attended={1} label={'Contatos Realizados'}/>
        </div>
      </Slider>
    </>
  );
}

export default LeadCarousel;
