import React, { useEffect, useState } from 'react';

import { Row, Card, CardBody, CardTitle } from 'reactstrap'

import api from '../../../../services/apiClient';
import Loading from '../../../Loading';

const CancelMonth = () => {
  const [qtd, setQtd] = useState();
  const [credit, setCredit] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getTotalSales = async () => {
      const response = await api.get('vindimonth')
      setQtd(response.data.qtd)
      setCredit(response.data.credit)
      setLoading(false)
    }
    getTotalSales();

  }, []);

  return (

    <Card style={{ marginLeft: 15,  height: 113}} className="card-stats mb-4 mb-xl-0">
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle
              tag="h5"
              className="text-uppercase text-muted mb-0"
            >
              Vendas c/ Vindi / Mês
            </CardTitle>
            {
              loading ?
                <Loading />
                :
                <>
                  <span className="h2 font-weight-bold mb-0 text-primary">{qtd} </span>
                  <small>cota(s)</small><br/>
                  <span className="h2 font-weight-bold mb-0 text-primary"> R$ {
                    credit ?
                      credit.toLocaleString('pt-BR')
                      :
                      '000.000,00'
                  }</span>
                </>

            }
          </div>
          {/* <Col className="col-auto">
            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
              <i className="fas fa-chart-bar" />
            </div>
          </Col> */}
        </Row>
        {/* <p className="mt-3 mb-0 text-muted text-sm">
          <span className="text-success mr-2">
            <i className="fa fa-arrow-up" />
            3.48%
          </span>{" "}
          <span className="text-nowrap">
            Comparado ao mês passado
          </span>
        </p> */}
      </CardBody>
    </Card>
  );
}

export default CancelMonth
