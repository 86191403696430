import React from 'react'

import {
  Card,
  Container,
  Row,
  Button,
  Col,
} from "reactstrap";

import HeaderRegister from "../../components/Header/HeaderRegister";
import Sidebar from '../../components/Sidebar';
import NavbarDash from '../../components/NavbarDash';
import Footer from '../../components/Footer';
import DtSale from '../../components/DTables/DtSales';


import { useModal } from  '../../hooks/modal';

function Register() {

  const { toggleModal } = useModal();


  return (
    <>
      <Sidebar />
      <div className="main-content">
        <NavbarDash title={'Cadastro'} />

        <HeaderRegister />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <div style={{padding:15}}>
              <div style={{paddingTop: 10, paddingBottom: 15}}>
                <Row>
                  <Col>
                    <Button color="primary" onClick={() => toggleModal({type: 4, idata: null, size: 'xl'})}>Novo Venda</Button>
                  </Col>
                  <Col></Col>
                  <Col></Col>
                </Row>
                <br />
                <DtSale />
              </div>
              </div>
            </Card>
          </div>
        </Row>
      </Container>

        <Container fluid>
          <Footer />
        </Container>
      </div>
    </>
  );
}

export default Register;
