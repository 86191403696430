import React,
{
  createContext,
  useContext,
  useCallback,
  useState
}
from 'react';

import { v4 as uuidv4 } from 'uuid';

import ToastContainer from '../components/ToastContainer';


const ToastContext = createContext();

const ToastProvider = ({ children }) => {
  const [messages, setMessges] = useState([]);

  const addToast = useCallback(({type, title, description}) => {
    const id = uuidv4();

    const toast = {
      id,
      type,
      title,
      description
    }

    setMessges((state) => [...state, toast]);

  },[]);

  const removeToast = useCallback((id) => {
    setMessges(state => state.filter(message => message.id !== id));
  },[]);

  return (
    <ToastContext.Provider value={{ addToast, removeToast}}>
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  );
}

function useToast() {
  const context = useContext(ToastContext);

  if(!context) {
    throw new Error('useToast must be used within a ToastProvider.');
  }

  return context;
}

export { ToastProvider, useToast };
