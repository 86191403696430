import React from 'react'

import {
  Card,
  Container,
  Row,
  Button,
  Col,
} from "reactstrap";

import Header from "../../components/Header/HeaderSale";
import Sidebar from '../../components/Sidebar';
import NavbarDash from '../../components/NavbarDash';
import Footer from '../../components/Footer';
import DtNewSale from '../../components/DTables/DtNewSales';


import { useModal } from  '../../hooks/modal';

function NewSale() {


  const { toggleModal } = useModal();


  return (
    <>
      <Sidebar />
      <div className="main-content">
        <NavbarDash title={'vendas'}/>

        <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <div style={{padding:15}}>
              <div style={{paddingTop: 10, paddingBottom: 15}}>
                <Row>
                  <Col>
                    <Button color="success" onClick={() => toggleModal({type: '3b', idata: null, size: 'lg'})}>Nova Venda</Button>
                  </Col>
                  <Col></Col>
                  <Col></Col>
                </Row>
                <br />
                <DtNewSale />
              </div>
              </div>
            </Card>
          </div>
        </Row>
      </Container>

        <Container fluid>
          <Footer />
        </Container>
      </div>
    </>
  );
}

export default NewSale;
