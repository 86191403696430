import React,
{
  createContext,
  useContext,
  useCallback,
  useState
}
from 'react';

import ModalContainer from '../components/ModalContainer';

const ModalContext = createContext();

const ModalProvider = ({ children }) => {

  const [stateModal, setModal] = useState(false);
  const [typeForm, setTypeForm] = useState();
  const [idata, setIdata] = useState({});
  const [size, setSize] = useState({});

  const toggleModal = useCallback(({type, idata, size}) => {
    setModal(!stateModal);
    setTypeForm(type);
    setIdata(idata);
    setSize(size);
  },[stateModal]);

  return (
    <ModalContext.Provider value={{ toggleModal }}>
      {children}
      <ModalContainer dataModal={{ stateModal, toggleModal, typeForm, idata, size }}/>
    </ModalContext.Provider>
  );
}

function useModal() {
  const context = useContext(ModalContext);

  if(!context) {
    throw new Error('useToast must be used within a ToastProvider.');
  }

  return context;
}

export { ModalProvider, useModal };
