import React, { useState } from 'react';
import classnames from 'classnames';

import {
  Col,
  Container,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

import { useModal } from '../../../hooks/modal';
import ItemsSchedule from './ItemsSchedule';
import EditLead from './forms/EditLead';
import NewContact from './forms/NewContact';

const ModalLead = ({ idata }) => {

  const { toggleModal } = useModal();
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  return (
    <Container>
      <ModalHeader toggle={toggleModal} >Edição de Leads</ModalHeader>
      <ModalBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => { toggle('1'); }}
            >
              Cadastro
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => { toggle('2'); }}
            >
              Agendamentos
          </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <br />
            <EditLead idata={idata} />
          </TabPane>

          <TabPane tabId="2">
            <br />
            <Col style={{ height: 230, overflowY: 'auto' }} className="border border-info rounded">
              {idata.schedules.map((item) => (
                <ItemsSchedule schedules={item} key={item.id} />
              ))}
            </Col>
            <br />
            <NewContact id={idata.id} />
          </TabPane>
        </TabContent>

      </ModalBody>
    </Container>
  );
}

export default ModalLead;
