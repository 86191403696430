import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'reactstrap';

import '../../assets/datatable/jquery.dataTables.min.css';
import api from '../../services/apiClient';
import { useModal } from '../../hooks/modal';

const $  = require( 'jquery' );
$.DataTable = require('datatables.net-bs4');

function DTables() {
  const tableRef = useRef();
  const [datagrid , setDatagrid] = useState([]);

  const { toggleModal } = useModal();

  useEffect(() => {
    const getDatagrid = async () => {
      const response = await api.get(
        'lead',
        {headers: {
            Authorization: `Bearer ${localStorage.getItem('@autocon:token')}`
        }}
      );
      setDatagrid(response.data);

      let $el = $(tableRef.current);
      $el.DataTable({
        destroy: true,
        "scrollX": true,
        "bProcessing": true,
        fixedHeader: true,
        language: {
          lengthMenu: "_MENU_",
          zeroRecords: "Sem registros cadastrados.",
          info: "Mostrar _PAGE_ de _PAGES_",
          infoEmpty: "Sem registros",
          infoFiltered: "(Filtrado por _MAX_ total de SENFs)",
          search: "Pesquisar",
          loadingRecords: "Carregando registros",
          processing: "Processando...",
          paginate: {
            first: ">",
            previous: "<",
            next: ">",
            last: "<"
          }
        },
      });
    }
    getDatagrid();
  },[]);

  function getStatus(schedules){
    let status = schedules.filter(item => item.attended === null);
    if(status.length > 0) return (<span className="badge badge-danger">{status.length} - contato pendente</span>);
    if(status.length === 0) return (<span className="badge badge-success">sem pendências</span>);
  }

   return (
    <div>
      <table className="table table-striped table-bordered" width="100%" ref={tableRef} >
        <thead>
          <tr>
            <th>Ações</th>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Celular</th>
            <th>Satatus</th>
          </tr>
        </thead>
        <tbody>
          {datagrid.map((data) => (

              <tr key={data.id}>
                <td>
                  <Button
                    type='button'
                    color="success"
                    size="sm"
                    onClick={() => toggleModal({type: '1b', idata: data, size: 'md'})}
                  >
                    <i className="ni ni-settings" />
                   </Button>
                  {/* <Button
                    type='button'
                    color="danger"
                    size="sm"
                  >
                    <i className="ni ni-archive-2" />
                  </Button> */}

                </td>
                <td>{data.name}</td>
                <td>{data.email}</td>
                <td>{ data.contacts[0] ? data.contacts[0].phone : '--' }</td>
                <td>{getStatus(data.schedules)}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
}

export default DTables;
