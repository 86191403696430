import React, { useCallback } from 'react';
import { Modal } from 'reactstrap';

import ModalLead from './ModalLead';
import ModalLeadEdit from './ModalLeadEdit';
import ModalClient from './ModalClient';
import ModalSale from './ModalSale';
import ModalNewSale from './ModalNewSale';
import ModalNewSaleEdit from './ModalNewSaleEdit';
import ModalSaleCancel from './ModalSaleCancel';
import ModalUser from './ModalUser';
import ModalRegister from './ModalRegister';
import ModalRegisterEdit from './ModalRegisterEdit';
import ModalInstalment from './ModalInstalment';
import ModalWelcome from './ModalWelcome';


const ModalContainer = ({ dataModal }) => {

  const { stateModal, toggleModal, typeForm, idata, size } = dataModal;

  const multiForm = useCallback(() => {
    if(typeForm === 1) return (<ModalLead idata={idata}/>);
    if(typeForm === '1b') return (<ModalLeadEdit idata={idata}/>);
    if(typeForm === 2) return (<ModalClient idata={idata}/>);
    if(typeForm === 3) return (<ModalSale idata={idata}/>);
    if(typeForm === '3b') return (<ModalNewSale idata={idata}/>);
    if(typeForm === '3c') return (<ModalNewSaleEdit idata={idata}/>);
    if(typeForm === '3d') return (<ModalSaleCancel idata={idata}/>);
    if(typeForm === 4) return (<ModalRegister idata={idata}/>);
    if(typeForm === '4b') return (<ModalRegisterEdit idata={idata}/>);
    if(typeForm === 5) return (<ModalUser idata={idata}/>);
    if(typeForm === 6) return (<ModalInstalment idata={idata}/>);
    if(typeForm === 7) return (<ModalWelcome idata={idata}/>);

  },[typeForm, idata]);

  return (
    <div>
      <Modal isOpen={stateModal} toggle={toggleModal} size={size}>
        {multiForm()}
      </Modal>
    </div>
  );
}

export default ModalContainer;
