import React from 'react';

import { Row, Col } from 'reactstrap';

import Input from '../../Input';
import Select from '../../Select';
import InputMaskPhone from '../../InputMaskPhone'

const FieldsAddress = () => {
  return (
    <>
      <Row>
        <Col>
          <Select
            name="type_id"
            options={[
                {value: '', label: 'Tipo'},
                {value: '1', label: 'Residencial'},
                {value: '2', label: 'Comercial'}
              ]}
          />
        </Col>
        <Col xs="5"><Input placeholder="Rua,Travessa,etc..." type="text" name="street" /></Col>
        <Col><Input placeholder="Numero" type="text" name="number" /></Col>
        <Col xs="4"><Input placeholder="Complemento" type="text" name="complement" /></Col>
      </Row>
      <br />
      <Row>
        <Col><InputMaskPhone placeholder="CEP" type="text" name="cep" /></Col>
        <Col><Input placeholder="Bairro" type="text" name="district" /></Col>
        <Col><Input placeholder="Cidade" type="text" name="city" /></Col>
        <Col><Input placeholder="Estado" type="text" name="state" /></Col>
      </Row>
    </>
  );

}

export default FieldsAddress;
