import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';

// utilizar defaultValue

 function Input({ name,...props}){
   const inputRef = useRef(null);
   const { fieldName, defaultValue, error, clearError, registerField } = useField(name);
   const [borderInput, setBorderInput] = useState('form-control');

   useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })

    const check = error ? 'form-control border border-danger' : 'form-control';
    setBorderInput(check);

   }, [fieldName, registerField, error])

   return (
    <>
      <input
        ref={inputRef}
        onFocus={clearError}
        className={borderInput}
        defaultValue={defaultValue}
        {...props}
        />
      {error && <small style={{display: 'block'}} className="text-danger">{error}</small>}
    </>
   );

 }

 export default Input;
