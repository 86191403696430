import React from 'react';

import {
  Card,
  Container,
  Row,
  Button,
  Col,
} from "reactstrap";

import HeaderLead from "../../components/Header/HeaderLead";
import Sidebar from '../../components/Sidebar';
import NavbarDash from '../../components/NavbarDash';
import Footer from '../../components/Footer';
import DtLeads from '../../components/DTables/DtLeads';
import { useModal } from  '../../hooks/modal';

function Lead() {
  const { toggleModal } = useModal();

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <NavbarDash title={'Leads'} />

        <HeaderLead />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">

              <div style={{padding:15}}>
              <div style={{paddingTop: 10, paddingBottom: 15}}>
                <Row>
                  <Col>
                    <Button color="primary" onClick={() => toggleModal({type: 1, idata: null, size: 'md'})}>Novo Lead</Button>
                  </Col>
                  <Col></Col>
                  <Col></Col>
                </Row>
              </div>
                <DtLeads />
              </div>
            </Card>
          </div>
        </Row>
      </Container>

        <Container fluid>
          <Footer />
        </Container>
      </div>
    </>
  );
}

export default Lead;
