export const contractStatus = [
  '',
  'Enviada',
  'Em análise',
  'Pendente documentação',
  'Pendente doc. mas subiu p/ pagamento',
  'Cancelada p/ vendedor',
  'Cancelada após input',
  'Concluída',
  'Aguardando Análise Watchdoc'
]

export const contractColor = [
  '',
  'text-info',
  'text-primary',
  'text-warning',
  'text-warning',
  'text-danger',
  'text-danger',
  'text-success',
  'text-primary'
]

export const docStatusColor = [
  'warning',
  'info',
  'success',
  'danger'
]

export const docStatusTxt = [
  'Pendente',
  'Pendente Aprovação',
  'Aprovado',
  'Reprovado'
]
