/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Col} from "reactstrap";

function Footer() {
  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6">
          <div className="copyright text-center text-xl-left text-muted">
            © 2020{" "}
            <a
              className="font-weight-bold ml-1"
              href="#"
              rel="noopener noreferrer"
              target="_blank"
            >
              Autocon CRM
            </a>
          </div>
        </Col>

        <Col xl="6">

        </Col>
      </Row>
    </footer>
  );
}

export default Footer;
