import React, { useState, useEffect } from 'react';

import api from '../../services/apiClient';
import Select from '../Select';

const SelectSalesman = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getOptions = async () => {
      const response = await api.get('salesman/31');
      let options = [{value: '', label: 'Vendedor'}];
      response.data.forEach((item) => {
        options.push({value: item.id, label: item.name })
      });
      setOptions(options);
    }
    getOptions();
  }, [setOptions]);

  return (
    <Select options={options} {...props} />
  );
}

export default SelectSalesman;
