import React, { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import { Scope } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import getValidationErrors from '../../../utils/getValidationErrors';

import {
  Col,
  Row,
  Container,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

import { v4 as uuidv4 } from 'uuid';

import { useModal } from '../../../hooks/modal';
import { useToast } from '../../../hooks/toast';

import Input from '../../Input';
import InputFile from '../../InputFile';
import InputMaskPhone from '../../InputMaskPhone';
import Select from '../../Select';
import SelectProduct from '../../SelectProduct';
import SelectPdv from '../../SelectPdv';
import TextArea from '../../TextArea';
import ViewerFiles from '../../ViewerFiles';
import { phoneRegex } from '../../../variables/Regex';
import { useViewer } from '../../../hooks/viewer'

import api from '../../../services/apiClient';
import InputMaskCpfCnpj from '../../InputMaskCpfCnpj';

const ModalNewSale = ({ idata }) => {

  const formRef = useRef(null);
  const { toggleModal } = useModal();
  const { addToast } = useToast();
  const [uuid] = useState(uuidv4());
  const [cpfData, setCpfData] = useState();
  const [activeTab, setActiveTab] = useState('1');
  const { removeViewAll } = useViewer();
  const [unity, setUnity] = useState();


  useEffect(() => {
    removeViewAll();
  },[removeViewAll]);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }


  const handleGetClient = useCallback(async (e) => {
    const response = await api.get(`client/${e.target.value}`);
    if (response) {
      setCpfData({person: response.data[0]});
    }
  }, []);


  // const handleGetClientPhone = useCallback(async (e) => {
  //   const phone = e.target.value.replace(' ', '');
  //   const response = await api.get(`lead/${phone}`);
  //   if (response && !cpfData) {
  //     // cpfData.email !== response.email && setCpfData({person: response.data[0]});
  //     setCpfData({person: response.data});
  //   }
  // }, []);

  const handleUnity = useCallback((e) => {
    setUnity(e.target.value)
  },[])




  const handleSubmit = useCallback(async (data, { reset }) => {
    try {
      formRef.current.setErrors({});
      const msg = 'Campo obrigatório';

      const schema = Yup.object().shape({
        person: Yup.object().shape({
          name: Yup.string().required(msg),
          cpf_cnpj: Yup.string().required(msg),
          email: Yup.string().email('Insira um email válido').required(msg).required(msg)
        }),
        contacts: Yup.array().of(
          Yup.object().shape({
            phone: Yup.string().matches(phoneRegex, 'Numero de telefone inválido'),
          })
        ),
        contract: Yup.object().shape({
          pdv: Yup.string().required(msg),
          product_id: Yup.string().required(msg),
        }),
        file: Yup.string().required(msg)
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const user = JSON.parse(localStorage.getItem('@autocon:person'));
      data.contract.parent_id = user.id;
      data.person.unity_id = user.unity_id;

      await api.post('sale', data);

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Venda cadastrada'
      });

      reset();
      setInterval(function () { document.location.reload(); }, 2000);

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err)
        formRef.current.setErrors(errors);
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Tente novamente mais tarde'
      });
    }
  }, [addToast]);

  return (
    <Container>
      <Form onSubmit={handleSubmit} initialData={cpfData} ref={formRef}>
        <Input type="hidden" name="contract.id" value={uuid} />
        <Input type="hidden" name="person.id" />
        <ModalHeader toggle={toggleModal} >CADASTRO DE VENDAS</ModalHeader>
        <ModalBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { toggle('1'); }}
              >
                Cadastro
          </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => { toggle('2'); }}
              >
                Documentos
          </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <br />
              <Row>
                <Col>
                  <InputMaskCpfCnpj placeholder="CPF/CNPJ" onBlur={handleGetClient} type="text" name="person.cpf_cnpj" />

                  <br />
                  <InputMaskPhone placeholder="Celular" type="text" name="contacts[0].phone" />
                  <br />
                  <Select type="select" name="contract.unity_id" onChange={handleUnity}
                    options={[
                      { value: '', label: 'Praça' },
                      { value: '1', label: 'Belém' },
                      { value: '2', label: 'Marabá' },
                      { value: '3', label: 'Parauapebas' },
                      { value: '4', label: 'São Luís' },
                      { value: '5', label: 'Aracaju' },
                    ]}
                  />
                  <br />
                  <SelectProduct name="contract.product_id" />
                </Col>
                <Col>
                  <Input placeholder="Nome do cliente" name="person.name" />
                  <br />
                  <Input placeholder="E-mail" type="text" name="person.email" />
                  <br />
                  <SelectPdv name="contract.pdv" unity={unity} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <br />
                  <TextArea name="contract.obs" rows="3" placeholder="Observações sobre a venda." />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <br />
              <Row>
                <Col>
                  <ViewerFiles />
                  <br/>
                  <p className="text-center">Uploads Documentos</p>
                  <InputFile name="file" label="Contrato GM" doctype="17" uuid={uuid} />
                  <Scope path="docfiles">
                    <InputFile name="file[0]" label="Documento oficial com foto" doctype="18" uuid={uuid} />
                    <InputFile name="file[1]" label="CPF" doctype="19" uuid={uuid} />
                    <InputFile name="file[2]" label="Comprovante de residência" doctype="20" uuid={uuid} />
                    <InputFile name="file[3]" label="Comprovante de pagamento" doctype="21" uuid={uuid} />
                    <InputFile name="file[4]" label="Seguro" doctype="22" uuid={uuid} />
                  </Scope>
                </Col>
              </Row>
            </TabPane>
          </TabContent>

        </ModalBody>
        <ModalFooter>
          <Button color="success" type="submit">Salvar</Button>{' '}
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Form>
    </Container>
  );
}

export default ModalNewSale;
