import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';

import '../../assets/datatable/jquery.dataTables.min.css';
import api from '../../services/apiClient';
import { useModal } from '../../hooks/modal';
import { contractStatus, contractColor } from '../../variables/status'

const $ = require('jquery');
$.DataTable = require('datatables.net-bs4');

function DTables() {
  const tableRef = useRef();
  const [datagrid, setDatagrid] = useState([]);

  const { toggleModal } = useModal();

  useEffect(() => {
    const getDatagrid = async () => {
      const response = await api.get('sale');
      setDatagrid(response.data);

      let $el = $(tableRef.current);
      $el.DataTable({
        destroy: true,
        "scrollX": true,
        "bProcessing": true,
        fixedHeader: true,
        language: {
          lengthMenu: "_MENU_",
          zeroRecords: "Sem registros cadastrados.",
          info: "Mostrar _PAGE_ de _PAGES_",
          infoEmpty: "Sem registros",
          infoFiltered: "(Resultado _MAX_ )",
          search: "Pesquisar",
          loadingRecords: "Carregando registros",
          processing: "Processando...",
          paginate: {
            first: ">",
            previous: "<",
            next: ">",
            last: "<"
          }
        },
      });
    }
    getDatagrid();
  }, []);

  return (
    <div>
      <table className="table table-striped table-bordered" width="100%" ref={tableRef} >
        <thead>
          <tr>
            <th>Ações</th>
            <th>Dt. Venda</th>
            <th>CPF/CNPJ</th>
            <th>Cliente</th>
            <th>Celular</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {datagrid.map((data) => (

            <tr key={data.id}>
              <td>
                <Button
                  type='button'
                  color="success"
                  size="sm"
                  onClick={() => toggleModal({ type: '3c', idata: data, size: 'lg' })}
                >
                  <i className="ni ni-settings" />
                </Button>
                <Button
                  type='button'
                  color="danger"
                  size="sm"
                  onClick={() => toggleModal({ type: '3d', idata: data.id, size: '' })}
                >
                  <i className="ni ni-fat-remove" />
                </Button>
              </td>
              <td>{moment(data.created_at).format("DD/MM/YYYY")}</td>
              <td>{data.people.cpf_cnpj}</td>
              <td>{data.people.name}</td>
              <td>{data.people.contacts.length > 0 && data.people.contacts[0].phone}</td>
              <td><strong
                className={contractColor[data.status_id]}>
                  {contractStatus[data.status_id]}
                </strong></td>
            </tr>
          ))
          }
        </tbody>
      </table>
    </div>
  );
}

export default DTables;
