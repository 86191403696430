import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/apiClient';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {

  const [data, setData] = useState(() => {
    const person = localStorage.getItem('@autocon:person');
    const user = localStorage.getItem('@autocon:user');
    const token = localStorage.getItem('@autocon:token');

    if(person && user && token){
      return {
        person: JSON.parse(person),
        user: JSON.parse(user),
        token
      }
    }

    return {};

  });

  const signIn = useCallback(async({ username, password }) => {
    const response = await api.post('session', {
      username,
      password,
    });

    const { person, user, token } = response.data;

    localStorage.setItem('@autocon:person', JSON.stringify(person));
    localStorage.setItem('@autocon:user', JSON.stringify(user));
    localStorage.setItem('@autocon:token', token);

    setData({ person, user, token })
  },[]);

  const signOut = useCallback(() => {
   localStorage.removeItem('@autocon:person');
   localStorage.removeItem('@autocon:user');
   localStorage.removeItem('@autocon:token');

   setData();

  },[]);

  return (
    <AuthContext.Provider value={{ person: data.person, user: data.user, signIn, signOut }} >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(){
  const context = useContext(AuthContext);

  if(!context){
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};



export { AuthProvider, useAuth };
